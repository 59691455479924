import React from 'react'
import { Carousel, Row, Col, Button } from 'antd'
import CarouselIndex from '../../components/Carousel'
import Title from '../../components/Title'
import CardCarousel from '../../components/CardCarouselB'
import '../../css/CampusPlatform.scss'
import { Helmet } from 'react-helmet';


const Carousel_text = [
  {
    carouselImage:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/img_campus-platform_banner .png',
    text:[
      {text:'助力校园创业，打造',colorText:''},
      {text:'',colorText:'一站式校园生活平台'}
    ],
    scenes:[
      {text:'聚焦校园外卖、快递代取、跑腿帮办等多元化应用场景'}
    ],
    background: '#324E8F',
    fontWeight:400
  }
]

const errandArr = [
  {
    icon:'campus-platform-moduleA-icon1',
    title:'多城市站'
  },
  {
    icon:'campus-platform-moduleA-icon2',
    title:'快递代取'
  },
  {
    icon:'campus-platform-moduleA-icon3',
    title:'代买零食'
  },
  {
    icon:'campus-platform-moduleA-icon4',
    title:'代扔垃圾'
  },
  {
    icon:'campus-platform-moduleA-icon5',
    title:'帮忙打印'
  },
  {
    icon:'campus-platform-moduleA-icon6',
    title:'作业辅导'
  },
  {
    icon:'campus-platform-moduleA-icon7',
    title:'万能帮办'
  },
  {
    icon:'campus-platform-moduleA-icon8',
    title:'教室占座'
  },
]

const takeOutArr= [
  {
    icon:'campus-platform-moduleA-icon9',
    title:'食堂外卖'
  },
  {
    icon:'campus-platform-moduleA-icon10',
    title:'校园美食'
  },
  {
    icon:'campus-platform-moduleA-icon11',
    title:'甜品蛋糕'
  },
  {
    icon:'campus-platform-moduleA-icon12',
    title:'奶茶饮品'
  },
  {
    icon:'campus-platform-moduleA-icon13',
    title:'美发护理'
  },
  {
    icon:'campus-platform-moduleA-icon14',
    title:'游泳健身'
  },
  {
    icon:'campus-platform-moduleA-icon15',
    title:'KTV唱歌'
  },
  {
    icon:'campus-platform-moduleA-icon16',
    title:'景区门票'
  },
]

const products = [
  {
    imgArr: [
      {
        img: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_bottom1.png",
        number: 0,
        title: "运营管理",
        list: [
          {
            icon:'campus-platform-moduleB-icon1',
            title:'丰富页面模板'
          },
          {
            icon:'campus-platform-moduleB-icon2',
            title:'多个页面组件'
          },
          {
            icon:'campus-platform-moduleB-icon3',
            title:'DIY配置'
          },
          {
            icon:'campus-platform-moduleB-icon4',
            title:'可视化操作'
          },
          {
            icon:'campus-platform-moduleB-icon5',
            title:'自定义频道分类'
          },
          {
            icon:'campus-platform-moduleB-icon6',
            title:'无限制频道添加'
          },
          {
            icon:'campus-platform-moduleB-icon7',
            title:'热搜词添加'
          },
          {
            icon:'campus-platform-moduleB-icon8',
            title:'跳转外部小程序'
          },
        ]
      },
      {
        img: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_bottom1(1).png",
        number: 1,
        title: "营销应用",
        list: [
          {
            icon:'campus-platform-moduleB-icon9',
            title:'邀请有礼'
          },
          {
            icon:'campus-platform-moduleB-icon10',
            title:'分享有礼'
          },
          {
            icon:'campus-platform-moduleB-icon11',
            title:'会员积分'
          },
          {
            icon:'campus-platform-moduleB-icon12',
            title:'积分商城'
          },
          {
            icon:'campus-platform-moduleB-icon13',
            title:'组合红包'
          },
          {
            icon:'campus-platform-moduleB-icon14',
            title:'充值赠送'
          },
          {
            icon:'campus-platform-moduleB-icon14',
            title:'新客立减'
          },
          {
            icon:'campus-platform-moduleB-icon16',
            title:'满减活动'
          },
          {
            icon:'campus-platform-moduleB-icon17',
            title:'新人优惠券'
          },
          {
            icon:'campus-platform-moduleB-icon18',
            title:'折扣优惠券'
          },
          {
            icon:'campus-platform-moduleB-icon19',
            title:'定向优惠券'
          },
          {
            icon:'campus-platform-moduleB-icon20',
            title:'减配送费'
          },
          {
            icon:'campus-platform-moduleB-icon21',
            title:'弹窗广告'
          },
          {
            icon:'campus-platform-moduleB-icon22',
            title:'折扣商品'
          },
          {
            icon:'campus-platform-moduleB-icon23',
            title:'优惠团购'
          }
        ]
      },
      {
        img: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_bottom1(3).png",
        number: 2,
        title: "校园多站加盟",
        list:[
          {
            title:'√ 多个站点共用同一小程序'
          },
          {
            title:'√ 分站骑手独立管理'
          },
          {
            title:'√ 站点间自由切换'
          },
          {
            title:'√ 支持管理本地商家'
          },
          {
            title:'√ 总站授予分站权限'
          },
          {
            title:'√ 分站城市自定义添加'
          },
          {
            title:'√ 分站商家独立结算'
          },
          {
            title:'√ 站点资金统一进入平台账户'
          },
        ]
      },
      {
        img: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_bottom1(2).png",
        number: 3,
        title: "管理权限",
        list: [
          {
            icon:'campus-platform-moduleB-icon24',
            title:'商家权限设置'
          },
          {
            icon:'campus-platform-moduleB-icon25',
            title:'设立计价规则'
          },
          {
            icon:'campus-platform-moduleB-icon26',
            title:'设置骑手佣金'
          },
          {
            icon:'campus-platform-moduleB-icon27',
            title:'制定奖惩规则'
          },
          {
            icon:'campus-platform-moduleB-icon28',
            title:'定制骑手等级'
          },
          {
            icon:'campus-platform-moduleB-icon29',
            title:'平台身份管理'
          },
          {
            icon:'campus-platform-moduleB-icon30',
            title:'添加运营员工'
          },
          {
            icon:'campus-platform-moduleB-icon31',
            title:'财务结算管理'
          },
          {
            icon:'campus-platform-moduleB-icon32',
            title:'平台经营分析'
          },
          {
            icon:'campus-platform-moduleB-icon33',
            title:'商家库存对账'
          }
        ]
      }
    ],
    list: [
      {
        icon: "campus-platform-moduleB-img1",
        title: "运营管理"
      },
      {
        icon: "campus-platform-moduleB-img2",
        title: "营销应用"
      },
      {
        icon: "campus-platform-moduleB-img3",
        title: "校园多站加盟"
      },
      {
        icon: "campus-platform-moduleB-img4",
        title: "管理权限"
      }
    ]
  }
];

const superiorityArr = {
  others:[
    {
      title:"临时开发团队",
      content:"外包公司或者临时组建的团队低成本开发，技术人员不稳定，技能不达标，导致系统存在很多潜在BUG，无法正常运营"
    },
    {
      title:'倒卖的劣质源码',
      content:"很多非专业公司看穿许多客户只贪图低价的劣根性，低价购买功能缺失的源码来转卖客户，不仅售后无保障，运营过程也会问题连连"
    },
    {
      title:'售后服务堪忧',
      content:"一锤子买卖，购买后没有售后人员配置，或者没有及时售后服务，导致在运营过程中无系统安全和指导保障"
    },
    {
      title:'价格参差不齐',
      content:"没有标准化、透明化的产品价格体系，不能享受到标准、正规的客户体验，客户自身权益无法得到保障"
    },
    {
      title:'没有更新升级',
      content:"购买的时候产品什么样，后期一直是那样，产品不会升级，没有迭代，无法更新，不能应对新市场需要"
    },
    {
      title:'售前售后大相径庭',
      content:"售前售后服务不一致，只自顾自的做产品，没有定期客户回访和建议收取，不解决客户实际运营问题"
    }
  ],
  ourself:[
    {
      title:"正规实力企业",
      content:"趣来达系统由成都壹立科技公司研发，具有资深的软件开发工程师和完整的技术团队，实力护航"
    },
    {
      title:'原生开发项目',
      content:"从产品逻辑、UI设计、到技术开发、运营测试，再到运维维护，完全原生、独立开发，一流技术服务"
    },
    {
      title:'一对一专人售后',
      content:"签约合作后立刻分配一对一专人售后客服对接，提供标准、完善、优质的售后服务，5*12h远程在线指导"
    },
    {
      title:'价格统一透明',
      content:"正规企业提供标准化服务，全套产品价格体系公开透明，签约合作保障双方权益，绝不多收一分钱"
    },
    {
      title:'保持高频更新',
      content:"公司长线自营产品，保持高频的更新迭代速度，根据市场需求和客户需要不断丰富产品，保持强有力的市场竞争力"
    },
    {
      title:'聆听客户建议',
      content:"公司有严格的售后部门管理制度，要求售后定期回访老客户，聆听每位客户的建议，统一反馈至产品部"
    }
  ]
}

let pageTimer = '' //停留时间

class DispatchSystem extends React.Component {
  state = {
    index:0,
    plateIndexB:0
  };

  componentWillMount() {
    // 获取进入页面的时间
		if (pageTimer === '') {
			pageTimer = parseInt(new Date().getTime() / 1000);
			console.log(pageTimer,'pageTimer')
		}
    this.props.onVisitClick({
      type: 1,
      domain: 'www.qolai.cn',
      page: 'campus_platform'
    },0)
  }

  componentWillUnmount() {
    // 离开时间减去进入时间
    const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
    console.log(tempTimer,'tempTimer')
    // 清空时间戳
    pageTimer = '';
    if (tempTimer > 0) {
      this.props.onVisitClick({
        type: 2,
        domain: 'www.qolai.cn',
        page: 'campus_platform',
        time: tempTimer
      },0)
    }
  }

  goToIndexb = index => {
    // this.logical.slick.slickGoTo(index)
    console.log(index);
    // this.logical.slick.slickGoTo(index);
    this.setState({
      plateIndexB: Number(index)
    });
  };
  slickGoToIndex = index => {
    this.slider.slick.slickGoTo(index);
    this.setState({
      index: index
    });
  };
  

  render() {
    return (
      <div>
        <Helmet>
          <title>趣来达-校园外卖跑腿系统-食堂外卖-快递代送代取</title>
          <meta name="Description" content="趣来达校园外卖跑腿系统，深度剖析校园生活服务运营场景，针对学生需求痛点与运营难点，一站式帮扶包括运营、营销、校区加盟、管理方式等解决方案，全方位满足校园创业者运营所需，趣来达助力校园创业，打造一站式校园生活平台" />
          <meta name="Keywords" content="校园生活平台,校园跑腿小程序,校园外卖小程序" />
        </Helmet>
        {/**顶部banner**/}
        <CarouselIndex data={Carousel_text} getIPs={this.props.getIPs} />
        
        {/* 为什么选择趣来达外卖系统 */}
        <div style={{marginTop:'80px', marginBottom:'100px'}}>
          <Title title="打造个性化校园平台，丰富页面模板风格自由切换" 
                 text="template" 
                 dec="针对跑腿、外卖不同服务模块，趣来达分别提供有页面风格多样化的模板类型供校园运营者选择，高颜值模板、高"
                 decs="度自由的DIY页面，帮助校园创业者打造高端的、个性化平台"
                 width={710}
                 height={66} />
          <Row>
            <Col sm={24} xl={24}>
              <div className="d-flex justify-content-center">
                <div className="d-flex" style={{ width: '1440px'}}>
                  <div 
                      className={this.state.index === 0 ? "pages-activate pages-nonactivated" : "pages-nonactivated"}
                      onMouseOver={() => {
                        this.slickGoToIndex(0);
                      }}
                  >
                    <p>跑腿页面鉴赏</p>
                  </div>
                  <div 
                      className={this.state.index === 1? "pages-activate pages-nonactivated" : "pages-nonactivated"}
                      onMouseOver={() => {
                        this.slickGoToIndex(1);
                      }}
                  >
                    <p>外卖页面鉴赏</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} xl={24} style={{ marginTop: "47px" }}>
              <Carousel dec={false} ref={slider => (this.slider = slider)}>
                <div className="d-flex flex_column align-cen">
                  <div className="d-flex jsbet" style={{width:'1440px'}}>
                    {
                      errandArr.map((item,k)=>{
                        return (
                          <div className="campus-platform-moduleA-top">
                            <img alt="" 
                              style={{
                                width: '60px',
                                height: '60px',
                              }}
                              src={require(`../../asset/CampusPlatform/${item.icon}.png`)}/>
                            {item.title}
                          </div>
                        )
                      })
                    }
                  </div>
                  <img alt="" 
                      style={{
                        width: '1440px',
                        height: '560px',
                      }}
                      src={require('../../asset/CampusPlatform/campus-platform-moduleA-img1.png')}/>
                  
                </div>
                <div className="d-flex flex_column align-cen">
                  <div className="d-flex jsbet" style={{width:'1440px'}}>
                    {
                      takeOutArr.map((item,k)=>{
                        return (
                          <div className="campus-platform-moduleA-top">
                            <img alt="" 
                              style={{
                                width: '60px',
                                height: '60px',
                              }}
                              src={require(`../../asset/CampusPlatform/${item.icon}.png`)}/>
                            {item.title}
                          </div>
                        )
                      })
                    }
                  </div>
                  <img alt="" 
                      style={{
                        width: '1440px',
                        height: '560px',
                      }}
                      src={require('../../asset/CampusPlatform/campus-platform-moduleA-img2.png')}/>
                  
                </div>
              </Carousel>
            </Col>
            <Col sm={24} xl={24} className="text-center">
              <Button
                ghost
                onClick={() => {
                  this.props.onVisitClick({
                    type: 3,
                    domain: 'www.qolai.cn',
                    page: 'campus_platform',
                    button: 'trialExperience'
                  }, () => window.location.href = "/system_oto")
                  this.props.showDialog();
                }}
                className="order-manage-btn2"
              >
                更多模板请前往后台试用体验
              </Button>
            </Col>
          </Row>
        </div>


        {/**提供全面的订单调度管理**/}
        <div className="main-3" style={{background:'#F8F8F8'}}>
          <Title
            title="校园O2O平台运营系统 · 首选趣来达"
            dec="深度剖析校园生活服务运营场景，针对学生需求痛点与运营难点，定制包括运营、营销、校区加盟、管理方式等一"
            decs="系列解决方案，全方位满足校园创业者运营所需"
            text="Oto platform"
            width={710}
            height={66}
          />
          <div>
            {products.map((item, index) => {
              return (
                <div className="d-flex justify-content-center" key={index}>
                  <div
                    className="d-flex"
                    style={{
                      width: "1440px",
                      height: "800px",
                      boxShadow: "0px 8px 30px 0px rgba(162,162,162,0.2)",
                      border: "1px solid #EBEBEB",
                      background: "#ffffff"
                    }}
                  >
                    <div className="logical-system-left">
                      {item.list.map((row, index2) => {
                        return (
                          <div key={index2}>
                            <div
                              className={`logical-system-box ${
                                this.state.plateIndexB === index2 ? "logical-system-box-active2" : ""
                              }`}
                              onMouseOver={() => {
                                this.goToIndexb(index2);
                              }}
                            >
                              <div className="logical-system-title3">
                                <img alt=""
                                  src={require(`../../asset/CampusPlatform/${row.icon}.png`)}
                                  
                                />
                                <div style={{ marginTop: 26,fontWeight:this.state.plateIndexB === index2 ? "900" : "" }}>{row.title}</div>
                              </div>
                              <span/>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="logical-system-right2">
                      <div className={`logical-system-right2-box ${
                             this.state.plateIndexB === 0 ? "fadeIn animated05s" : "fadeOut animated05s"}`}>
                        <div className="d-flex wrap jsbet" style={{padding:'0px 60px'}}>
                          {
                            
                            products[0].imgArr[0].list.map((v,k)=>{
                              return(
                                <div className="campus-platform-moduleB-box" style={{width:'236px',height:'236px',marginBottom:'30px'}}>
                                  <img alt=""
                                    src={require(`../../asset/CampusPlatform/${v.icon}.png`)}
                                    style={{width:'80px',height:'80px'}}
                                  />
                                  {v.title}
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>

                      <div className={`logical-system-right2-box ${
                             this.state.plateIndexB === 1 ? "fadeIn animated05s" : "fadeOut animated05s"}`}>
                        <div className="d-flex wrap jsbet"  style={{padding:'0px 60px'}}>
                        {
                            
                            products[0].imgArr[1].list.map((v,k)=>{
                              return(
                                <div className="campus-platform-moduleB-box" style={{width:'182px',height:'182px',marginBottom:'30px'}}>
                                  <img alt=""
                                    src={require(`../../asset/CampusPlatform/${v.icon}.png`)}
                                    style={{width:'80px',height:'80px'}}
                                  />
                                  {v.title}
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>

                      <div className={`logical-system-right2-box ${
                             this.state.plateIndexB === 2 ? "fadeIn animated05s" : "fadeOut animated05s"}`}>
                        <div className="d-flex wrap jsbet" style={{padding:'0px 103px'}}>
                          {
                              
                              products[0].imgArr[2].list.map((v,k)=>{
                                return(
                                  <div className="campus-platform-moduleB-list">
                                    {v.title}
                                  </div>
                                )
                              })
                            }
                        </div>
                        <img alt="" src={require('../../asset/CampusPlatform/campus-platform-moduleB-img5.png')} className='campus-platform-moduleB-bg'/>
                      </div>

                      <div className={`logical-system-right2-box ${
                             this.state.plateIndexB === 3 ? "fadeIn animated05s" : "fadeOut animated05s"}`}>
                        <div className="d-flex wrap jsbet"  style={{padding:'0px 60px'}}>
                          {
                            products[0].imgArr[3].list.map((v,k)=>{
                              return(
                                <div className="campus-platform-moduleB-box" style={{width:'182px',height:'182px',marginBottom:'30px'}}>
                                  <img alt=""
                                    src={require(`../../asset/CampusPlatform/${v.icon}.png`)}
                                    style={{width:'80px',height:'80px'}}
                                  />
                                  {v.title}
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* 亮点功能，玩转同城生活 */}
      <div style={{  backgroundImage: `url(${require('../../asset/CampusPlatform/campus-platform-moduleC-bg.png')})`, backgroundSize: '100% auto', backgroundRepeat: ' no-repeat' , marginTop: 100, paddingTop: 98 }}>
        <Title
          title="趣来达助力校园创业 · 遍布"
          titleColor="#FFFFFF"
          spanColor="全国800+校区"
          text="Campus entrepreneurial"
          textColor="#FFFFFF"
          width={710}
          height={66}
        />
        <CardCarousel/>
      </div>
        
        {/* 为什么选择趣来达外卖系统 */}
        <div style={{marginTop:'80px'}}>
          <Title title="校园创业，为何优选趣来达校园平台系统" 
                text="reason to choose" 
                width={710}
                height={66} />
          <div className="d-flex justify-content-center">
            <div className="d-flex jsbet"  style={{ width: '1440px',height:'1080px'}}>
              <div className="d-flex jsbet flex_column" style={{width:'705px'}}>
                {
                  superiorityArr.others.map((item,index)=>{
                    return (
                      <div className="campus-platform-moduleD-box" style={{background:'#F8F8F8'}}>
                        <div className="campus-platform-moduleD-box-title" style={{background:'#E8E8E8'}}>
                          <img alt="" src={require(`../../asset/CampusPlatform/campus-platform-moduleD-icon1.png`)}/>
                          {item.title}
                        </div>
                        <p>
                          {item.content}
                        </p>
                      </div>
                    )
                  })
                }
              </div>

              <div className="d-flex jsbet flex_column" style={{width:'705px'}}>
                {
                  superiorityArr.ourself.map((item,index)=>{
                    return (
                      <div className="campus-platform-moduleD-box" style={{background: '#FFFFFF',boxShadow: '0px 3px 17px 0px rgba(0, 0, 0, 0.08)'}}>
                        <div className="campus-platform-moduleD-box-title" style={{background:'linear-gradient(90deg, #FFCC0C, #F6BB07)',boxShadow:'0px 12px 12px 0px rgba(246, 187, 7, 0.24)'}}>
                          <img alt="" src={require(`../../asset/CampusPlatform/campus-platform-moduleD-icon2.png`)}/>
                          {item.title}
                        </div>
                        <p>
                          {item.content}
                        </p>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
        
        {/* 为什么选择趣来达外卖系统 */}
        <div style={{marginTop:'80px'}}>
          <Title title="趣来达专业售后服务" 
                text="after-sales" 
                dec="专注本地O2O系统领域，多年的运营实战与开发服务经验，强大的技术开发团队，全方位产品功能优势，为外卖平台运营商提供完善的业务解决方案"
                width={710}
                height={66} />
          <div className="d-flex justify-content-center">
            <div className="detaile-module5-box"  style={{ width: '1440px',height:'540px', flexWrap:'wrap'}}>
              
                <div className="detaile-module5-box-item" style={{width:'264px',height:'230px'}}>
                  <img alt="" 
                        style={{
                          width: '60px',
                          height: '60px',
                        }}
                        src={require(`../../asset/CampusPlatform/campus-platform-moduleE-icon1.png`)}/>
                  <div
                    style={{
                      marginTop: '20px',
                      fontSize: '20px',
                      color: '#333333',
                      lineHeight:'36px',
                      textAlign:'center',
                      fontWeight: 'bold'
                    }}
                  >
                    公众号/小程序<br/>免费代注册
                  </div>
                </div>

                <div className="detaile-module5-box-item" style={{width:'264px',height:'230px'}}>
                  <img alt="" 
                        style={{
                          width: '60px',
                          height: '60px',
                        }}
                        src={require(`../../asset/CampusPlatform/campus-platform-moduleE-icon2.png`)}/>
                  <div
                    style={{
                      marginTop: '20px',
                      fontSize: '20px',
                      color: '#333333',
                      lineHeight:'36px',
                      textAlign:'center',
                      fontWeight: 'bold'
                    }}
                  >
                    公众号平台配置
                  </div>
                </div>
                
                <div className="detaile-module5-box-item" style={{width:'264px',height:'230px'}}>
                  <img alt="" 
                        style={{
                          width: '60px',
                          height: '60px',
                        }}
                        src={require(`../../asset/CampusPlatform/campus-platform-moduleE-icon3.png`)}/>
                  <div
                    style={{
                      marginTop: '20px',
                      fontSize: '20px',
                      color: '#333333',
                      lineHeight:'36px',
                      textAlign:'center',
                      fontWeight: 'bold'
                    }}
                  >
                    小程序搭建上线
                  </div>
                </div>

                <div className="detaile-module5-box-item" style={{width:'264px',height:'230px'}}>
                  <img alt="" 
                        style={{
                          width: '60px',
                          height: '60px',
                        }}
                        src={require(`../../asset/CampusPlatform/campus-platform-moduleE-icon4.png`)}/>
                  <div
                    style={{
                      marginTop: '20px',
                      fontSize: '20px',
                      color: '#333333',
                      lineHeight:'36px',
                      textAlign:'center',
                      fontWeight: 'bold'
                    }}
                  >
                    微信支付配置
                  </div>
                </div>

                <div className="detaile-module5-box-item" style={{width:'264px',height:'230px'}}>
                  <img alt="" 
                        style={{
                          width: '60px',
                          height: '60px',
                        }}
                        src={require(`../../asset/CampusPlatform/campus-platform-moduleE-icon5.png`)}/>
                  <div
                    style={{
                      marginTop: '20px',
                      fontSize: '20px',
                      color: '#333333',
                      lineHeight:'36px',
                      textAlign:'center',
                      fontWeight: 'bold'
                    }}
                  >
                    微信支付配置
                  </div>
                </div>

                <div className="detaile-module5-box-item" style={{width:'264px',height:'230px'}}>
                  <img alt="" 
                        style={{
                          width: '60px',
                          height: '60px',
                        }}
                        src={require(`../../asset/CampusPlatform/campus-platform-moduleE-icon6.png`)}/>
                  <div
                    style={{
                      marginTop: '20px',
                      fontSize: '20px',
                      color: '#333333',
                      lineHeight:'36px',
                      textAlign:'center',
                      fontWeight: 'bold'
                    }}
                  >
                    自动打款<br/>开通配置
                  </div>
                </div>

                <div className="detaile-module5-box-item" style={{width:'264px',height:'230px'}}>
                  <img alt="" 
                        style={{
                          width: '60px',
                          height: '60px',
                        }}
                        src={require(`../../asset/CampusPlatform/campus-platform-moduleE-icon7.png`)}/>
                  <div
                    style={{
                      marginTop: '20px',
                      fontSize: '20px',
                      color: '#333333',
                      lineHeight:'36px',
                      textAlign:'center',
                      fontWeight: 'bold'
                    }}
                  >
                    系统操作文档
                  </div>
                </div>

                <div className="detaile-module5-box-item" style={{width:'264px',height:'230px'}}>
                  <img alt="" 
                        style={{
                          width: '60px',
                          height: '60px',
                        }}
                        src={require(`../../asset/CampusPlatform/campus-platform-moduleE-icon8.png`)}/>
                  <div
                    style={{
                      marginTop: '20px',
                      fontSize: '20px',
                      color: '#333333',
                      lineHeight:'36px',
                      textAlign:'center',
                      fontWeight: 'bold'
                    }}
                  >
                    运营指导文档
                  </div>
                </div>

                <div className="detaile-module5-box-item" style={{width:'264px',height:'230px'}}>
                  <img alt="" 
                        style={{
                          width: '60px',
                          height: '60px',
                        }}
                        src={require(`../../asset/CampusPlatform/campus-platform-moduleE-icon9.png`)}/>
                  <div
                    style={{
                      marginTop: '20px',
                      fontSize: '20px',
                      color: '#333333',
                      lineHeight:'36px',
                      textAlign:'center',
                      fontWeight: 'bold'
                    }}
                  >
                    一对一<br/>远程售后
                  </div>
                </div>

                <div className="detaile-module5-box-item" style={{width:'264px',height:'230px'}}>
                  <img alt="" 
                        style={{
                          width: '60px',
                          height: '60px',
                        }}
                        src={require(`../../asset/CampusPlatform/campus-platform-moduleE-icon10.png`)}/>
                  <div
                    style={{
                      marginTop: '20px',
                      fontSize: '20px',
                      color: '#333333',
                      lineHeight:'36px',
                      textAlign:'center',
                      fontWeight: 'bold'
                    }}
                  >
                    免费提供<br/>服务协议
                  </div>
                </div>
               
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default DispatchSystem;