import React from 'react';
import '../css/cardCarousel.scss';


const cardCarouselArr = [
  {img:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/takeaway/9/img_iphonex_page1.png',text:'物流订单'},
  {img:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/takeaway/9/img_iphonex_page4.png',text:'收取件码'},
  {img:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/takeaway/9/img_iphonex_page5.png',text:'团购套餐'},
  {img:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/takeaway/9/img_iphonex_page6.png',text:'开屏广告'},
  {img:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/takeaway/9/img_iphonex_page7.png',text:'红包满减'},
  {img:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/takeaway/9/img_iphonex_page8.png',text:'邀请有礼'},
  {img:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/takeaway/9/img_iphonex_page2.png',text:'站点加盟'},
  {img:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/takeaway/9/img_iphonex_page3.png',text:'魔方导航'},
  {img:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/takeaway/9/img_iphonex_page9(1).png',text:'骑手等级'},
  {img:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/takeaway/9/img_iphonex_page9.png',text:'商户中心'},
  {img:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/takeaway/9/img_iphonex_page11.png',text:'扫码核销'},
]
 const numList = ["p0", "p1", "p2", "p3", "p4", "p5", "p6", "p7", "p8",'p9','p10'];

export default React.memo(() => {
  const Ref = React.createRef()
  React.useEffect(() => {
    let Item =  Ref.current.childNodes;
    for (let i = 0; i < Item.length; i++) {
      Item[i].className = `carousel-itme ${numList[i]}`;
    }
  },[])

  const fncarousel = (index,fn,fn1) => {
    let Item =  Ref.current.childNodes;
    numList[fn](numList[index]);
    numList[fn1]();
    for (let i = 0; i < Item.length; i++) {
      Item[i].className = `carousel-itme ${numList[i]}`;
    }
  }

  const leftClick = () => {
    fncarousel(10,'unshift','pop')
  }
  const rightClick = () => {
    fncarousel(0,'push','shift')
  }

  return (
    <div className='carousel-div d-flex justify-content-center'>
      <div style={{position:'relative'}}>
        <div className='carousel-box d-flex' ref={Ref}>
          {
            cardCarouselArr.map((item,index) => (
              <div  className='carousel-itme' key={index}>
                <img  src={item.img}  alt={item.text} />
                {/* <div className='carousel-but'>{item.text}</div> */}
              </div>
            ))
          }
        </div>
        <img onClick={() => rightClick()} className='right-img' src="https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/takeaway/9/next.png" alt=""/>
        <img onClick={() => leftClick()} className='left-img' src="https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/takeaway/9/return.png" alt=""/>
      </div>
      
    </div>
  );
})