import React from 'react'
import { Carousel, Row, Col, Button } from 'antd'
import CarouselIndex from '../../components/Carousel'
import Title from '../../components/Title'
import '../../css/dispatchSystem.scss'
import { Helmet } from 'react-helmet';


const Carousel_text = [
  {
    carouselImage: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/img_dispatch_system_banner.png",
    text: [{ text:'专注智能调度配送，支持', colorText: ""},{ text: "第三方对接，聚合配送", colorText: "美团/饿了么" }],
    scenes: [],
    characteristic:[
      { text:'√ 引进先进智能算法，提供跑腿服务高效解决方案' },
      { text:'√ 开放配送系统API接口，支持第三方对接' },
      { text:'√ 聚合配送，一键解决配送运力问题' }
    ],
    background: '#273361',
    fontWeight:400
  }
]
const module6Arr = [
  {
    icon:'dispatch_systemmoduleC-icon1',
    title: '对接美团/饿了么',
    text:'对接国内大型外卖平台商家自配送通道，来单直接发往配送池'
  },
  {
    icon:'dispatch_systemmoduleC-icon2',
    title: '开放API接口',
    text:
      '开放跑腿系统API接口，支持更多第三方外卖平台订单对接'
  },
  {
    icon:'dispatch_systemmoduleC-icon3',
    title: '聚合配送',
    text:
      '支持系统内部订单聚合配送，直接发往第三方平台'
  },
  {
    icon:'dispatch_systemmoduleC-icon4',
    title: '自定义计价方案',
    text:
      '支持范围、里程、距离、按单、单额计价等多种计价方式'
  },
  {
    icon:'dispatch_systemmoduleC-icon5',
    title: '特殊附加计价',
    text:
      '支持特殊场景（如时段、天气）等附加计费方式，自动加入计价'
  },
  {
    icon:'dispatch_systemmoduleC-icon6',
    title: '骑手管理制度',
    text:'支持骑手保险，骑手公告、保证金缴纳，设立奖惩制度、骑手等级、排行等，区分兼职与全职'
  },
  {
    icon:'dispatch_systemmoduleC-icon7',
    title: '商户计价',
    text:
      '可设置计价方式添加不同商家，也可以针对不同商家单独设置计价方式'
  },
  {
    icon:'dispatch_systemmoduleC-icon8',
    title: '数据统计',
    text:
      '图表式展示，相关经营（场景、骑手、商家）数据一目了然'
  }
]

let pageTimer = '' //停留时间

class DispatchSystem extends React.Component {
  state = {
    index:0
  };

  componentWillMount() {
    // 获取进入页面的时间
		if (pageTimer === '') {
			pageTimer = parseInt(new Date().getTime() / 1000);
			console.log(pageTimer,'pageTimer')
		}
    this.props.onVisitClick({
      type: 1,
      domain: 'www.qolai.cn',
      page: 'dispatch_system'
    },0)
  }

  componentWillUnmount() {
    // 离开时间减去进入时间
    const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
    console.log(tempTimer,'tempTimer')
    // 清空时间戳
    pageTimer = '';
    if (tempTimer > 0) {
      this.props.onVisitClick({
        type: 2,
        domain: 'www.qolai.cn',
        page: 'dispatch_system',
        time: tempTimer
      },0)
    }
  }


  slickGoToIndex = index => {
    this.slider.slick.slickGoTo(index);
    this.setState({
      index: index
    });
  };
  

  render() {
    return (
      <div>
        <Helmet>
          <title>趣来达-智能调度系统-配送系统-聚合配送系统</title>
          <meta name="Description" content="趣来达智能调度配送系统支持美团/饿了么等第三方对接，聚合市面上多数跑腿平台，实现聚合配送，引进先进智能算法，为跑腿行业提供高效解决方案。" />
          <meta name="Keywords" content="智能调度系统,配送系统,聚合配送系统" />
        </Helmet>
        {/**顶部banner**/}
        <CarouselIndex data={Carousel_text} getIPs={this.props.getIPs} />
        
        {/* 趣来达同城配送调度系统逻辑体系 */}
        <div style={{marginTop:'80px', marginBottom:'20px'}}>
          <Title title="专注同城配送调度，提供配送解决方案" 
                 text="solution"
                 height={66} />
          <div className="d-flex justify-content-center" style={{ width: '100%',height:'800px',backgroundImage: `url(${require('../../asset/Dispatch_system/dispatch_systemmoduleA-map.png')})`, backgroundSize: '100% 100%', backgroundRepeat: ' no-repeat'}}>
            <div className="d-flex"  style={{ width: '1440px'}}>
              <div className="d-flex"  style={{ width: '375px',height: '796px',position:"relative"}}>
                <div className="d-flex flex_column align-cen" style={{top:'5px',right:"199px",position:'absolute'}}>
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleA-icon1.png`)} style={{width:'60px',height:'60px',marginBottom:'12px'}}/>
                  <p style={{fontSize:'20px',color:'#121213',fontWeight:'bold',marginBottom:'0px'}}>对接美团</p>
                </div>
                <div className="d-flex flex_column align-cen" style={{top:'115px',right:"82px",position:'absolute'}}>
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleA-icon2.png`)} style={{width:'60px',height:'60px',marginBottom:'12px'}}/>
                  <p style={{fontSize:'20px',color:'#121213',fontWeight:'bold',marginBottom:'0px'}}>对接饿了么</p>
                </div>
                <div className="d-flex flex_column align-cen" style={{top:'208px',right:"261px",position:'absolute'}}>
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleA-icon3.png`)} style={{width:'60px',height:'60px',marginBottom:'12px'}}/>
                  <p style={{fontSize:'20px',color:'#121213',fontWeight:'bold',marginBottom:'0px'}}>开放API接口</p>
                </div>
                <div className="d-flex flex_column align-cen" style={{top:'287px',right:"36px",position:'absolute'}}>
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleA-icon4.png`)} style={{width:'60px',height:'60px',marginBottom:'12px'}}/>
                  <p style={{fontSize:'20px',color:'#121213',fontWeight:'bold',marginBottom:'0px'}}>聚合配送</p>
                </div>
                <div className="d-flex flex_column align-cen" style={{top:'382px',right:"241px",position:'absolute'}}>
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleA-icon5.png`)} style={{width:'60px',height:'60px',marginBottom:'12px'}}/>
                  <p style={{fontSize:'20px',color:'#121213',fontWeight:'bold',marginBottom:'0px'}}>智能调度</p>
                </div>
                <div className="d-flex flex_column align-cen" style={{top:'469px',right:"122px",position:'absolute'}}>
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleA-icon6.png`)} style={{width:'60px',height:'60px',marginBottom:'12px'}}/>
                  <p style={{fontSize:'20px',color:'#121213',fontWeight:'bold',marginBottom:'0px'}}>后台指派</p>
                </div>
                <div className="d-flex flex_column align-cen" style={{top:'609px',right:"74px",position:'absolute'}}>
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleA-icon7.png`)} style={{width:'60px',height:'60px',marginBottom:'12px'}}/>
                  <p style={{fontSize:'20px',color:'#121213',fontWeight:'bold',marginBottom:'0px'}}>骑手转单</p>
                </div>
                <div className="d-flex flex_column align-cen" style={{top:'663px',right:"276px",position:'absolute'}}>
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleA-icon8.png`)} style={{width:'60px',height:'60px',marginBottom:'12px'}}/>
                  <p style={{fontSize:'20px',color:'#121213',fontWeight:'bold',marginBottom:'0px'}}>自主抢单</p>
                </div>
              </div>
              <div className="d-flex"  style={{ width: '690px',height: '796px' ,padding:'60px',boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.08)',borderRadius: '40px'}}>
                <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleA-img.png`)} style={{width:'570px',height:'676px'}}/>
              </div>
              <div className="d-flex "  style={{ width: '375px',height: '796px',position:"relative"}}>
                  
                <div className="d-flex flex_column align-cen" style={{top:'16px',left:"145px",position:'absolute'}}>
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleA-icon9.png`)} style={{width:'60px',height:'60px',marginBottom:'12px'}}/>
                  <p style={{fontSize:'20px',color:'#121213',fontWeight:'bold',marginBottom:'0px'}}>收件码</p>
                </div>
                <div className="d-flex flex_column align-cen" style={{top:'103px',left:"257px",position:'absolute'}}>
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleA-icon10.png`)} style={{width:'60px',height:'60px',marginBottom:'12px'}}/>
                  <p style={{fontSize:'20px',color:'#121213',fontWeight:'bold',marginBottom:'0px'}}>取件码</p>
                </div>
                <div className="d-flex flex_column align-cen" style={{top:'173px',left:"55px",position:'absolute'}}>
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleA-icon11.png`)} style={{width:'60px',height:'60px',marginBottom:'12px'}}/>
                  <p style={{fontSize:'20px',color:'#121213',fontWeight:'bold',marginBottom:'0px'}}>拍照下单</p>
                </div>
                <div className="d-flex flex_column align-cen" style={{top:'290px',left:"159px",position:'absolute'}}>
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleA-icon12.png`)} style={{width:'60px',height:'60px',marginBottom:'12px'}}/>
                  <p style={{fontSize:'20px',color:'#121213',fontWeight:'bold',marginBottom:'0px'}}>短信服务</p>
                </div>
                <div className="d-flex flex_column align-cen" style={{top:'396px',left:"269px",position:'absolute'}}>
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleA-icon13.png`)} style={{width:'60px',height:'60px',marginBottom:'12px'}}/>
                  <p style={{fontSize:'20px',color:'#121213',fontWeight:'bold',marginBottom:'0px'}}>手动录单</p>
                </div>
                <div className="d-flex flex_column align-cen" style={{top:'532px',left:"62px",position:'absolute'}}>
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleA-icon14.png`)} style={{width:'60px',height:'60px',marginBottom:'12px'}}/>
                  <p style={{fontSize:'20px',color:'#121213',fontWeight:'bold',marginBottom:'0px'}}>骑手位置监控</p>
                </div>
                <div className="d-flex flex_column align-cen" style={{top:'663px',left:"249px",position:'absolute'}}>
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleA-icon15.png`)} style={{width:'60px',height:'60px',marginBottom:'12px'}}/>
                  <p style={{fontSize:'20px',color:'#121213',fontWeight:'bold',marginBottom:'0px'}}>骑手定位追踪</p>
                </div>

              </div>
            </div>
          </div>
        </div>
        {/* 趣来达同城配送调度系统逻辑体系 */}
        <div style={{marginTop:'80px', marginBottom:'20px'}}>
          <Title title="趣来达同城配送调度系统逻辑体系" 
                 text="logical system" 
                 dec="系统内部订单+第三方订单，多渠道订单获取"
                 decs="系统平台配送+聚合配送，多元化订单配送"
                 width={710}
                 height={66} />
          <div className="d-flex justify-content-center">
            <div className="logical-system"  style={{ width: '1440px'}}>
                <img alt="" src={require(`../../asset/Dispatch_system/img-logical-system.png`)} style={{width:'1440px',height:'430px',marginLeft:'8px',marginTop:'38px'}}/>
            </div>
          </div>
        </div>
        {/**提供全面的订单调度管理**/}
        <div className="main-3" style={{background:'#ffffff'}}>
          <Title
            title="提供全面的订单调度管理"
            dec="实时了解订单配送进程，在线监控骑手位置"
            text="excellent case"
            width={710}
            height={66}
          />
          <Row>
            <Col sm={24} xl={24}>
              <div className="tag-main d-flex justify-content-center">
                <div
                  className={this.state.index === 0 ? "tag-active tag" : "tag"}
                  id="tag1"
                  onMouseEnter={() => {
                    this.slickGoToIndex(0);
                  }}
                >
                  订单调度管理
                </div>
                <span />
                <div
                  className={this.state.index === 1 ? "tag-active tag" : "tag"}
                  id="tag2"
                  onMouseEnter={() => {
                    this.slickGoToIndex(1);
                  }}
                >
                  骑手位置监控
                </div>
                <span />
                <div
                  className={this.state.index === 2 ? "tag-active tag" : "tag"}
                  id="tag3"
                  onMouseEnter={() => {
                    this.slickGoToIndex(2);
                  }}
                >
                  后台订单指派
                </div>
                <span />
                <div
                  className={this.state.index === 3 ? "tag-active tag" : "tag"}
                  id="tag3"
                  onMouseEnter={() => {
                    this.slickGoToIndex(3);
                  }}
                >
                  查看配送进程
                </div>
              </div>
            </Col>
            <Col sm={24} xl={24} style={{ marginTop: "80px" }}>
              <Carousel dec={false} ref={slider => (this.slider = slider)}>
                <div className="d-flex justify-content-center">
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleD-img1.png`)} style={{width:'1290px',height:'505px'}}/>
                </div>
                <div className="d-flex justify-content-center">
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleD-img2.png`)} style={{width:'1290px',height:'505px'}}/>
                </div>
                <div className="d-flex justify-content-center" style={{ height: 506 }}>
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleD-img3.png`)} style={{width:'1290px',height:'505px'}}/>
                </div>
                <div className="d-flex justify-content-center" style={{ height: 506 }}>
                  <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleD-img4.png`)} style={{width:'1290px',height:'505px'}}/>
                </div>
              </Carousel>
            </Col>
            <Col sm={24} xl={24} className="text-center">
              <Button
                ghost
                onClick={() => {
                  this.props.onVisitClick({
                    type: 3,
                    domain: 'www.qolai.cn',
                    page: 'dispatch_system',
                    button: 'onTrial'
                  },0);
                  this.props.showDialog();
                }}
                className="order-manage-btn"
              >
                立即试用<span style={{fontWeight:700,marginLeft:5}}> more+</span>
              </Button>
            </Col>
          </Row>
        </div>
        {/**配送体系全逻辑流程**/}
        <div style={{ padding: "80px 0 0 0", backgroundImage: `url(${require('../../asset/systemoto/system-oto-modulesB-bg.png')})`, backgroundSize: '100% 100%', backgroundRepeat: ' no-repeat' }}>
          <Title
            title="配送体系全逻辑流程"
            dec="多元化配送方式，确保配送效率高效安全"
            text="flow chart"
            titleColor='#FFFFFF'
            textColor='rgb(255, 255, 255)'
            decColor='#FFFFFF'
            width={750}
            height={68}
          />
          <div className="d-flex justify-content-center">
            <img alt="" src={require(`../../asset/Dispatch_system/dispatch_systemmoduleC-img.png`)} style={{ width: '1440px', height: "589px", marginBottom: '100px' }} />
          </div>
        </div>
        
        {/* 为什么选择趣来达外卖系统 */}
        <div style={{marginTop:'80px'}}>
          <Title title="为什么选择趣来达外卖系统" 
                text="reason" 
                dec="专注本地O2O系统领域，多年的运营实战与开发服务经验，强大的技术开发团队，全方位产品功能优势，为外卖平台运营商提供完善的业务解决方案"
                width={710}
                height={66} />
          <div className="d-flex justify-content-center">
            <div className="detaile-module5-box"  style={{ width: '1440px',height:'620px', flexWrap:'wrap'}}>
              {module6Arr.map(item => {
                return (
                  <div className="detaile-module5-box-item" style={{width:'338px',height:'276px'}}>
                    <img alt="" 
                          style={{
                            width: '60px',
                            height: '60px',
                          }}
                          src={require(`../../asset/Dispatch_system/${item.icon}.png`)}/>
                    <div
                      style={{
                        marginTop: '20px',
                        fontSize: '20px',
                        color: '#333333',
                        marginBottom: '19px',
                        fontWeight: 'bold'
                      }}
                    >
                      {item.title}
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        color: '#707070',
                        fontWeight:'600',
                        textAlign:'center'
                      }}
                    >
                      {item.text}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default DispatchSystem;