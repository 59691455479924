import React, { useState, useEffect } from "react";
let timer = null
function Side(props) {
    const [probationState, setProbationState] = useState(false);
    const [up, setUp] = useState(false);
    const [qqState, setQqState] = useState(false);
    const [phoneState, setPhoneState] = useState(false);
    const [weChateState, setWeChateState] = useState(false);
    const [reLine, setReLine] = useState([]); // 在线联系
    useEffect(() => {
        timer = setInterval(() => {
            setReLine(document.getElementsByClassName("embed-group-icon__item"));
            if(document.getElementsByClassName("embed-group-icon__item")) {
                clearInterval(timer);
            }
        }, 1000);
    }, []);
    return (
        <div className="side-box">
            <div
                style={{ background: probationState ? "#fff" : "#F6BB07" }}
                className="side-item d-flex justify-content-center align-items-center"
                onMouseLeave={() => {
                    setProbationState(false);
                }}
                onMouseEnter={() => {
                    setProbationState(true);
                }}
            >
                <div
                    className="d-flex justify-content-center align-items-center flex-column"
                    onClick={() => {
                        props.showDialog();
                    }}
                >
                    <img
                        src={require(`../asset/${
                            probationState ? "slider_icon1-1" : "slider_icon1-2"
                        }.png`)}
                        className="side-item-icon d-block"
                    />
                    <span
                        className="d-block text-center"
                        style={{ color: probationState ? "#F6BB07" : "#121213" }}
                    >
            试用系统
          </span>
                </div>
            </div>
            {reLine.length > 0 && (
                <div
                    style={{ background: qqState ? "#fff" : "#F6BB07" }}
                    className="side-item d-flex justify-content-center align-items-center"
                    onMouseLeave={() => {
                        setQqState(false);
                    }}
                    onMouseEnter={() => {
                        setQqState(true);
                    }}
                >
                    <div
                        className="d-flex justify-content-center align-items-center flex-column"
                        onClick={() => {
                            reLine[0].click();
                        }}
                    >
                        <img
                            src={require(`../asset/${
                                qqState ? "slider_icon2-1" : "slider_icon2-2"
                            }.png`)}
                            className="side-item-icon d-block"
                        />
                        <span
                            className="d-block text-center"
                            style={{ color: qqState ? "#F6BB07" : "#121213" }}
                        >
              在线咨询
            </span>
                    </div>
                </div>
            )}
            <div
                style={{ background: phoneState ? "#fff" : "#F6BB07" }}
                className="side-item"
                onMouseLeave={() => {
                    setPhoneState(false);
                }}
                onMouseEnter={() => {
                    setPhoneState(true);
                }}
            >
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <img
                        src={require(`../asset/${
                            phoneState ? "slider_icon3-1.png" : "slider_icon3-2.png"
                        }`)}
                        className="side-item-icon d-block"
                    />
                    <span
                        className="d-block text-center"
                        style={{ color: phoneState ? "#F6BB07" : "#121213" }}
                    >
            电话咨询
          </span>
                </div>
                <div
                    className={`side-right-frame align-items-center animated fadeInRight`}
                    style={{ display: phoneState ? "flex" : "none" }}
                >
                    <div>
                        <span className="d-block">18771040879</span>
                    </div>
                </div>
            </div>
            <div
                className="side-item"
                style={{ background: weChateState ? "#fff" : "#F6BB07" }}
                onMouseLeave={() => {
                    setWeChateState(false);
                }}
                onMouseEnter={() => {
                    setWeChateState(true);
                }}
            >
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <img
                        src={require(`../asset/${
                            weChateState ? "slider_icon4-1.png" : "slider_icon4-2.png"
                        }`)}
                        className="side-item-icon d-block"
                    />
                    <span
                        className="d-block text-center"
                        style={{ color: weChateState ? "#F6BB07" : "#121213" }}
                    >
            扫码加微
          </span>
                </div>
                <div
                    className={`side-right-frame2 align-items-center justify-content-center animated fadeInRight`}
                    style={{ display: weChateState ? "flex" : "none" }}
                >
                    <div style={{ display: "grid" }}>
                        <img
                            src={require("../asset/weChatCode.png")}
                            className="side-weChate-code"
                        />
                        <span className="text-center">微信扫一扫立即咨询</span>
                    </div>
                </div>
            </div>
            <div
                style={{ background: up ? "#fff" : "#F6BB07" }}
                className="side-item d-flex justify-content-center align-items-center"
                onClick={() => {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                }}
                onMouseLeave={() => {
                    setUp(false);
                }}
                onMouseEnter={() => {
                    setUp(true);
                }}
            >
                <img
                    src={require(`../asset/${
                        up ? "slider_icon5-1.png" : "slider_icon5-2.png"
                    }`)}
                    className="side-item-icon d-block"
                />
            </div>
        </div>
    );
}

Side.defaultProps = {};

export default Side;
