import React from 'react';
import Pagination from 'element-react/dist/npm/es6/src/pagination';
import dayjs from 'dayjs';
import { Button, Row, Col, Input,message } from 'antd';
import { Helmet } from 'react-helmet';
// apis
import {
  newsList,
  getHotNewsList
} from '../../apis/Information';
import {
  Link
} from 'react-router-dom';
// components
import CarouselIndex from "../../components/Carousel";

const Carousel_text = [
  {
    carouselImage: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/information_banner_bg1.png",
    text: [{ text: "追踪全行业", colorText: "新动态",br:true },{ text: "了解科技发展新风向", colorText: "" }],
    scenes: [],
    
  }
];

let pageTimer = '' //停留时间

class information extends React.Component {
  state = {
    newList: [],
    pageNum: 1, // 第几页
    total: 10, // 总页数
    pageSize: 10, // 每页显示多少
    hotNewsList: [], // 热门新闻
    new_key:''
  }

  async componentWillMount() {
    await this.fetchData()
    // 获取进入页面的时间
		if (pageTimer === '') {
			pageTimer = parseInt(new Date().getTime() / 1000);
			console.log(pageTimer,'pageTimer')
		}
    this.props.onVisitClick({
      type: 1,
      domain: 'www.qolai.cn',
      page: 'information'
    },0)
  }

  componentWillUnmount() {
    // 离开时间减去进入时间
    const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
    console.log(tempTimer,'tempTimer')
    // 清空时间戳
    pageTimer = '';
    if (tempTimer > 0) {
      this.props.onVisitClick({
        type: 2,
        domain: 'www.qolai.cn',
        page: 'information',
        time: tempTimer
      },0)
    }
  }

  pageChange = currentPage => {
    this.setState({
      pageNum: currentPage
    }, async () => {
      await this.fetchData()
    })
    document.body.scrollTop = document.documentElement.scrollTop = 640;
  }
  
 
  fetchData = async () => {
    const result = await newsList(5, this.state.pageNum,'')
    if (result.code !== 200) {
      message.error(result.message || '获取失败');
      return
    }
    const hotNewsList = await getHotNewsList();
    if (hotNewsList.code !== 200) {
      message.error(result.message || '获取失败');
      return
    }
    this.setState({
      newList: result.data.list,
      total: result.data.total,
      pageNum: this.state.pageNum,
      pageSize: 10,
      hotNewsList: hotNewsList.data.list || []
    })
  }
  
  setKey(e){
    this.setState({
      new_key:e
    })
  }
  searchArticle = async () =>{
    if(this.state.new_key.length < 2){
      message.error('检索的关键字字数不得小于两个字');
      return
    }
    this.setState({
      pageNum: 1
    })
    const result = await newsList(5, 1,this.state.new_key)
    if (result.code === 200) {
      this.setState({
        newList: result.data.list,
        total: result.data.total,
        pageNum: 1,
        pageSize: 10,
      })
    }else {
      message.error(result.message || '获取失败');
    }
    
  }
  render() {
    return (
      <div style={{background: '#F8F8F8'}}>
        <Helmet>
          <title>趣来达-城市配送系统|跑腿行业|跑腿公司-同城配送解决方案</title>
          <meta name="Description" content="趣来达同城配送是专业的城市、县城、校园跑腿系统,配送骑士APP+商户小程序+配送团队管理端（包含小程序）+无线打印机接单,开放第三方配送接口,接收各大电商平台、外卖平台订单。" />
          <meta name="Keywords" content="同城跑腿软件,配送管理软件,城市配送系统,跑腿服务软件,跑腿派单,快递货运系统,微信派单,物流同城配送跑腿解决方案" />
        </Helmet>
        {/**顶部banner**/}
        <CarouselIndex data={Carousel_text} getIPs={this.props.getIPs}/>
        <Row type="flex" justify="center" style={{marginTop: '80px'}}>
          <Col span={14}>
            <div className="information-page-header d-flex align-items-center">
              <img alt="img" src={require('../../asset/information/icon1.png')} style={{width: '20px', height: '20px'}}/>
              <span>当前位置：趣来达 > 行业资讯</span>
            </div>
            {
            (this.state.newList||[]).map((item, index) => {
              return (
                <Link key={index} className="information-news-list" to={`/information_detail?id=${item.news_id}`} onClick={() => {
                  sessionStorage.setItem("isActive", 4);
                }}>
                  <div className="d-flex information-card">
                    {/**左侧图片**/}
                    {
                      item.news_photos &&
                        <div style={{marginRight: '30px'}}>
                          <img alt="img" src={item.news_photos} style={{width: '270px',height: '180px', borderRadius: '10px'}} />
                        </div>
                    }
                    {/**右侧文字**/}
                    <div className="d-flex align-content-between flex-wrap">
                      <h5 className="m-0 information-news-title">{item.news_title}</h5>
                      <p className="information-news-main m-0 fw-600">
                        {item.remark}
                      </p>
                      <div className="w-100 d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <img alt="img" src={require('../../asset/information/icon2.png')} style={{width: '14px', height: '14px'}} />
                          <span style={{fontSize: '14px', color: '#A2A2A2',marginLeft: '6px'}}>{dayjs(item.create_time).format('YYYY年M月D日')}</span>
                        </div>
                        <div className="d-flex align-items-center">
                           <img alt="img" src={require('../../asset/information/icon3.png')} style={{width: '14px', height: '14px'}} />
                           <span style={{fontSize: '14px', color: '#A2A2A2', marginLeft: '6px'}}>{item.preset_number + item.browse_number}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            })
          }
          </Col>
          <Col span={5} style={{paddingTop: 70}}>
          <Input.Group compact>
            <Input style={{ width: 'calc(100% - 100px)',marginBottom:'20px' }} placeholder="请输入关键词（多个关键词以空格隔开）" onChange={e => {this.setKey(e.currentTarget.value)}}/>
            <Button type="primary" onClick={() => this.searchArticle()}>搜索</Button>
          </Input.Group>
            <h2>行业动态</h2>
            <ul style={{paddingLeft: '17px'}}>
              {
                this.state.hotNewsList.map((item, index) => (
                  <li key={index} style={{maxWidth: 293, marginBottom: '10px'}}>
                    <a href={item.link} target="blank">{item.news_title}</a>
                  </li>
                ))
              }
            </ul>
          </Col>
        </Row>
        {/**分页**/}
        <div className="d-flex justify-content-center align-items-center" style={{paddingTop: '60px', paddingBottom: '80px'}}>
          <Pagination layout="prev, pager, next, jumper" total={this.state.total} pageSize={this.state.pageSize} currentPage={this.state.pageNum}  onCurrentChange={(currentPage) => {this.pageChange(currentPage)}}/>
          <Button  size="small" type="primary">跳转</Button>
        </div>
      </div>
    )
  }
}

export default information;