import React from "react";
import { message, Carousel, Row, Col, } from "antd";
import "../../App.css";
import "../../css/home.scss";
import { homeArr} from "../../mock/index";
import dayjs from 'dayjs';
import CarouselIndex from "../../components/Carousel";
import { Helmet } from 'react-helmet';

import {
  newsList,
} from '../../apis/Information';
import { submit } from '../../apis/App';
// components
import Title from "../../components/Title";
import title2 from "../../asset/img_ text_profit.png";
import title3 from "../../asset/img_text_benchmarking.png";
// imgs
import homeTitle4 from "../../asset/homeTitle4.png";
import homeTitle5 from "../../asset/homeTitle5.png";
import homeTitle6 from "../../asset/homeTitle6.png";

const falseNumbers = [
    {
      name:'王**',
      phoneNumber:'183******90'
  },{
      name:'李**',
      phoneNumber:'139******18'
  },{
      name:'黄**',
      phoneNumber:'152******31'
  },{
      name:'袁**',
      phoneNumber:'138******72'
  },{
      name:'熊**',
      phoneNumber:'130******78'
  },{
      name:'王**',
      phoneNumber:'183******90'
  },{
      name:'李**',
      phoneNumber:'139******18'
  },{
      name:'黄**',
      phoneNumber:'152******31'
  },{
      name:'袁**',
      phoneNumber:'138******72'
  },{
      name:'熊**',
      phoneNumber:'130******78'
  },
]

const deliveryArr = [
  {
    imgUrl: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/bianzu26.png",
    describe: ["覆盖代买、代送、代取等万", "能跑腿服务场景需求。"],
    text: "万能跑腿"
  },
  {
    imgUrl: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/bianzu22.png",
    describe: ["针对景区门票、游玩项目、特色美食项目，支持线上团购预约，线下消费核销。"],
    text: "景区团购"
  },
  {
    imgUrl: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/bianzu55.png",
    describe: ["涵盖维修、保洁等同城生活", "服务,线上预约，快速上门。"],
    text: "上门服务"
  },
  {
    imgUrl: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/bianzu24.png",
    describe: ["类似美团、饿了么的外卖平台", "系统,整合同城餐饮外送服务。"],
    text: "餐饮外卖"
  },
  {
    imgUrl: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/bianzu52.png",
    describe: ["除了同城即时配送，系统也支持","异地物流配送,特产支持电商模式。"],
    text: "生鲜配送"
  },
  {
    imgUrl: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/bianzu25.png",
    describe: ["聚合分类信息资讯，可设置二手", "闲置、同城交友、信息发布、表",'白墙等互动平台。'],
    text: "信息发布"
  }
];


const products = [
  {
    imgArr: [
      
      {
        img: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_bottom1.png",
        number: 0,
        title: "趣来达用户端 · H5+微信小程序+抖音小程序",
        dec: "小程序与公众号便捷入口，满足本地用户跑腿或订餐需求",
        top:'150px',
        code: [
          
          {
            img: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/douyin-code.png",
            codeText: [
              {
                title: "打开抖音",
                icon: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/ic_douyin-code.png",
                text: "【扫描二维码】",
                desc: "在线体验跑腿端"
              }
            ]
          },
          {
            img: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_code.png",
            codeText: [
              {
                title: "打开微信",
                icon: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/ic_weixin.png",
                text: "【扫描二维码】",
                desc: "在线体验跑腿端"
              }
            ]
          },
          {
            img: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_code2.png",
            codeText: [
              {
                title: "打开微信",
                icon: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/ic_weixin.png",
                text: "【扫描二维码】",
                desc: "在线体验外卖端"
              }
            ]
          }
        ]
      },
      {
        img: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_bottom1(1).png",
        number: 1,
        title: "趣来达骑手端 · 安卓+IOS",
        dec: "抢单/派单/转单/拒单，及时推送，资金提现，奖惩与等级制度",
        code: [
          {
            img: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_code(1).png",
            codeText: [
              {
                title: "打开浏览器",
                icon: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/ic_weixin(1).png",
                text: "【扫描二维码】",
                desc: "下载[Ios APP]"
              }
            ]
          },
          {
            img: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_code(2).png",
            codeText: [
              {
                title: "打开浏览器",
                icon: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/ic_weixin(2).png",
                text: "【扫描二维码】",
                desc: "下载[Android APP]"
              }
            ]
          }
        ]
      },
      {
        img: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_bottom1(3).png",
        number: 2,
        title: "趣来达商家端 · 安卓+IOS+PC",
        dec: "APP与后台数据互通，发单、接单、打印、设置店铺、上传商品、设置营销活动",
        btn: "点击前往商家后台",
        code: [
          {
            img: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_code(3).png",
            codeText: [
              {
                title: "打开浏览器",
                icon: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/ic_weixin(1).png",
                text: "【扫描二维码】",
                desc: "下载[Ios APP]"
              }
            ]
          },
          {
            img: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_code(4).png",
            codeText: [
              {
                title: "打开浏览器",
                icon: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/ic_weixin(2).png",
                text: "【扫描二维码】",
                desc: "下载[Android APP]"
              }
            ]
          }
        ]
      },
      {
        img: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_bottom1(2).png",
        number: 3,
        title: "趣来达调度后台 · PC",
        dec: "智能调度管理，页面风格化设计，人员管理与订单统计，商家权限管理。",
        btn: "点击前往调度后台"
      }
    ],
    list: [
      {
        icon: "zu12(3)",
        title: "趣来达用户端"
      },
      {
        icon: "zu12(4)",
        title: "趣来达骑手端"
      },
      {
        icon: "zu12(2)",
        title: "趣来达商家端"
      },
      {
        icon: "zu12(1)",
        title: "趣来达调度后台"
      }
    ]
  }
];

const caseArr = [
  {
    img: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/img_case1.png",
    mask: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/img_case1_t.png",
    title: "本地案例",
    text: "县城合作商利用趣来达外卖配送系统，打造出重庆市县级重点创业示范项目。"
  },
  {
    img: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/img_case2.png",
    mask: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/img_case2_t.png",
    title: "校园案例",
    text: "校园创业者，深耕校园餐饮外卖服务与跑腿配送，携手趣来达打造优质校园服务。"
  },
  {
    img: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/img_case3.png",
    mask: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/img_case3_t.png",
    title: "国外案例",
    text: "（日本客户）国外案例：海外外卖与跑腿配送服务先驱者，借力趣来达O2O系。"
  }
];

const Carousel_text = [
  {
    carouselImage: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/img_banner_bg1.png",
    text: [{ colorText: "趣来达",text: "同城外卖+跑腿系统"}],
    desc: "聚合多元化服务 · 打造本地智慧生活服务，满足用户、商家、骑手、运营者多角色全方位使用",
    scenes: [
      { text: "H5",icon:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/w.png' }, 
      { text: "小程序",icon:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/x.png' }, 
      { text: "APP(Android+IOS)",icon:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/s.png' }, 
      { text: " PC端",icon:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/pc.png' }
  ]
  },
  {
    carouselImage: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/img_banner_bg2.png",
    text: [{ text:'开放', colorText: "API接口", opposite:'true'},{ text: "，对接", colorText: "美团/饿了么", opposite:'true' },{text: "第三方平台，聚合配送"}],
    scenes: [],
    characteristic:[
      { text:'√ 支持授权绑定美团、饿了么' },
      { text:'√ 聚合配送，来单直接发往配送系统' },
      { text:'√ 开放API接口，支持自行对接' }
    ],
    background: '#273361',
    fontWeight:400
  },
  {
    carouselImage: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/img_banner_bg3.png",
    text: [{ text: "定制", colorText: "全套运营方案 " , opposite:'true'},{ text: "全面科技助力", colorText: "本地服务商",opposite:'true' }],
    scenes: [{ text: "免费维护 | 升级迭代 | 品牌定制 | 独立部署 | 运营指导 | 5*12H在线售后"}],
    iconscenes:[
      {icon:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/ic_banner1.png',text:'跑腿帮办',gray:true},
      {icon:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/ic_banner2.png',text:'外卖订餐',gray:true},
      {icon:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/ic_banner3.png',text:'上门服务',gray:true},
      {icon:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/ic_banner4.png',text:'美食团购',gray:true},
      {icon:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/ic_banner5.png',text:'连锁超市',gray:true}
  ],
    background: '#4B588B',
    fontWeight:400,
    marginTop:'179px'
  },
  {
    type:'douyin',
    carouselImage: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/img_takeaway_banner_douyin.png",
    text: [{ text: "字节小程序，入驻", colorText: "抖音", opposite:'true' },{ text: "和", colorText: "今日头条", opposite:'true' },{ text: "小程序等"}],
    icons:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/douyin-img.png',
    background: '#4B588B',
    fontWeight:400
  }
];

let pageTimer = '' //停留时间

class Home extends React.Component {
  state = {
    baneerIndex: 0,
    plateIndex: 0,
    plateIndexA: 0,
    plateIndexB: 0,
    newList:[],
    indexA:0,
    name:'',
    inputValue:'',
    pageNum:1
  };
  slickGoToIndex = index => {
    console.log(homeArr);
    this.slider.slick.slickGoTo(index);
    this.setState({
      plateIndex: Number(index)
    });
  };
  slickGoToIndexA = indexA => {
    this.slider.slick.slickGoTo(indexA);
    this.setState({
      indexA: indexA
    });
  };

  componentWillMount() {
    this.fetchData()
    // 获取进入页面的时间
		if (pageTimer === '') {
			pageTimer = parseInt(new Date().getTime() / 1000);
			console.log(pageTimer,'pageTimer')
		}
    this.props.onVisitClick({
      type: 1,
      domain: 'www.qolai.cn',
      page: 'home'
    },0)
  }

  componentWillUnmount() {
    // 离开时间减去进入时间
    const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
    console.log(tempTimer,'tempTimer')
    // 清空时间戳
    pageTimer = '';
    if (tempTimer > 0) {
      this.props.onVisitClick({
        type: 2,
        domain: 'www.qolai.cn',
        page: 'home',
        time: tempTimer
      },0)
    }
  }
  componentDidMount(){
     let width = document.getElementsByClassName('carousel-view')[0].offsetWidth
     document.getElementsByClassName('carousel-view')[0].children[0].children[0].children[0].children[0].querySelector('.slick-active').style.width = width

  }
  goToAdmin = () => {
    window.location.href = "http://www.qolai.com/admin#/user/register";
  };

  goToIndexb = index => {
    this.setState({
      plateIndexB: Number(index)
    });
  };

  fetchData = async () => {
    const result = await newsList(5, this.state.pageNum);
    if (result.code !== 200) {
      message.error(result.message || '获取失败');
      return
    }
    let arr = result.data.list ? result.data.list.splice(0,4) : []
    this.setState({
      newList:  arr,
    })
  }

  submitForm = async (dialogFormVisible) => {
    const phoneExp = /^1[3456789]\d{9}$/;
    const {name, inputValue} = this.state;
    let phone = '',
        wechat = ''
    if (name === '') {
      message.error('请正确填写姓名');
      return
    }
    if (inputValue === '') {
      message.error('请输入手机号码或微信号');
      return
      
    }else{
      if(phoneExp.test(inputValue)){
        phone = inputValue
      }else{
        wechat = inputValue
      }
    }

    const result = await submit(name,'', phone, wechat)
    if (result.code === 200) {
      message.success('提交成功！')
      this.setState({
        isShowDialog: false
      });
    } else {
      message.error('提交失败！')
      this.setState({
        isShowDialog: false
      });
    }
  }
  
  render() {
    return (
      <div className="w-100">
        <Helmet>
          <title>点餐系统-外卖软件-校园外卖小程序-跑腿配送软件</title>
          <meta name="Description" content="趣来达是成都壹立科技旗下一站式同城生活服务搭建平台，专业做公众号+小程序+APP+PC的一站式O2O系统，可快速搭建微信外卖订餐平台，校园点餐平台，同城跑腿配送平台等本地多元化服务平台；多站点运营聚合多元化服务，打造本地智慧生活服务，满足用户、商家、骑手、运营者多角色全方位使用。" />
          <meta name="Keywords" content="配送系统,跑腿APP,配送软件,配送小程序" />
        </Helmet>
        <CarouselIndex data={Carousel_text} getIPs={this.props.getIPs}/>
        <div className="d-flex flex-c-c" style={{marginTop:'50px'}}>
          <div className="index-collect" >
            <div className="index-collect-title"> 
                <div className="title-main">
                  <div className="title-main-box">
                    <span >趣来达外卖+跑腿系统</span>
                    <div className="main-bg" style={{zIndex:'1 !important'}}></div>
                  </div>
                    
                </div>
                <div className="input-box">
                  <div className="input-box-left">
                    <span>姓名</span>
                    <input ref='inputRef' placeholder="请输入姓名" onChange={e => this.setState({ name: e.currentTarget.value })}/>
                  </div>
                  <div className="input-box-right">
                    <span>电话/微信号</span>
                    <input ref='inputRef' placeholder="请输入电话/微信号" onChange={e => this.setState({ inputValue: e.currentTarget.value })}/>
                  </div>
                </div> 
            </div>
            <div className="submit-btn" onClick={(dialogFormVisible) => {
              this.props.onVisitClick({
                type: 3,
                domain: 'www.qolai.cn',
                page: 'home',
                button: 'onTrial'
              },0);
              this.submitForm(dialogFormVisible)}
            }>
              立即试用
            </div> 
            <div className="carousel-box">
              <div className="scrolList-box">
                {falseNumbers.map((item,index)=>{
                  return (
                    <div className="scrolList" style={{paddingTop:index === 0 ? '10px' : ''}}>
                      <div className="d-flex align-items-center">
                          <img alt="" src={require('../../asset/homeIcon/icon_trumpet.png')} style={{width:'16px',height:'16px',marginRight:'10px'}}/>
                          <span>{item.name}</span>
                      </div>
                      <span class="item2">{item.phoneNumber}</span>
                      <span class="item3">领取成功</span>
                    </div>
                  )
                })}
              </div>
            </div> 

          </div>
        </div>  

        {/**提供全面的订单调度管理**/}
        <div style={{paddingTop:'90px'}}>
          <Row>
            <Col sm={24} xl={24}>
              <div className="home-tags-top-wrap d-flex justify-content-center">
                <div className="home-tags-top">
                  <div className={this.state.indexA === 0 ? "home-tags-active home-tags" : "home-tags"}
                        onMouseEnter={() => {
                        
                          this.slickGoToIndexA(0);
                        }}>
                    <img alt="" src={require('../../asset/homeIcon/icon-home-tag1.png')}/>
                    <p className="home-tag-title1">同城跑腿帮办系统</p>
                    <p className="home-tag-title2">FIRST</p>
                    <span/>
                  </div>
                  <div className={this.state.indexA === 1 ? "home-tags-active home-tags" : "home-tags"}
                        onMouseEnter={() => {
                        
                          this.slickGoToIndexA(1);
                        }}>
                    <img alt="" src={require('../../asset/homeIcon/icon-home-tag2.png')}/>
                    <p className="home-tag-title1">智能配送调度系统</p>
                    <p className="home-tag-title2">SECOND</p>
                    <span/>
                  </div>
                  <div className={this.state.indexA === 2 ? "home-tags-active home-tags" : "home-tags"}
                        onMouseEnter={() => {
                        
                          this.slickGoToIndexA(2);
                        }}>
                    <img alt="" src={require('../../asset/homeIcon/icon-home-tag3.png')}/>
                    <p className="home-tag-title1">外卖订餐平台系统</p>
                    <p className="home-tag-title2">THIRD</p>
                    <span/>
                  </div>
                  <div className={this.state.indexA === 3 ? "home-tags-active home-tags" : "home-tags"}
                        onMouseEnter={() => {
                        
                          this.slickGoToIndexA(3);
                        }}>
                    <img alt="" src={require('../../asset/homeIcon/icon-home-tag4.png')}/>
                    <p className="home-tag-title1">校园外卖跑腿生活平台</p>
                    <p className="home-tag-title2">FOURTH</p>
                    <span/>
                  </div>
                  <div className={this.state.indexA === 4 ? "home-tags-active home-tags" : "home-tags"}
                        onMouseEnter={() => {
                        
                          this.slickGoToIndexA(4);
                        }}>
                    <img alt="" src={require('../../asset/homeIcon/icon-home-tag5.png')}/>
                    <p className="home-tag-title1">本地生活O2O系统</p>
                    <p className="home-tag-title2">FIFTH</p>
                    <span/>
                  </div>

                  <div className={this.state.indexA === 5 ? "home-tags-active home-tags" : "home-tags"}
                        onMouseEnter={() => {
                          this.slickGoToIndexA(5);
                        }}>
                    <img alt="" src={require('../../asset/homeIcon/icon-home-tag6.png')}/>
                    <p className="home-tag-title1">多平台运营系统</p>
                    <p className="home-tag-title2">SIXTH</p>
                    <span/>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} xl={24}>
              <Carousel dec={false} ref={slider => (this.slider = slider)}>
                <div className="d-flex justify-content-center">
                  <div className="home-tags-bottom1">
                    {/* left */}
                    <div className="home-tags-bottom1-left">
                        <h1>万能跑腿帮办系统</h1>
                        <p style={{fontSize:'20px',color:'#707070'}}>整合万能跑腿、上门服务等同城及时服务业务，系统化运营管理。</p>
                        <div className="home-tags-bottom1-left-content">
                          <p>
                          打通同城跑腿配送全流程，支持代买、代办、上门、代驾等多场景的跑腿配送服务，搭建有特色的高效跑腿配送平台，为配送、跑腿团队提供完整的解决方案。
                          </p>
                        </div> 
                        <div className="d-flex home-tags-bottom1-left-list">
                          <div className="d-flex flex_column align-cen" style={{marginRight:'50px'}}>
                            <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleA1.png')}/>
                            <span>代买代送</span>
                          </div>
                          <div className="d-flex flex_column align-cen" style={{marginRight:'50px'}}>
                            <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleA2.png')} />
                            <span>快递寄取</span>
                          </div>
                          <div className="d-flex flex_column align-cen" style={{marginRight:'50px'}}>
                            <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleA3.png')}/>
                            <span>上门服务</span>
                          </div>
                          <div className="d-flex flex_column align-cen" style={{marginRight:'50px'}}>
                            <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleA4.png')}/>
                            <span>同城代驾</span>
                          </div>
                        </div>
                        <div className="d-flex align-cen" style={{marginTop:"50px"}}>
                          <div className="home-tags-more-button" 
                               onClick={() => {
                                this.props.onVisitClick({
                                  type: 3,
                                  domain: 'www.qolai.cn',
                                  page: 'home',
                                  button: 'experience'
                                },() => window.location.href = 'https://www.qolai.com/admin');
                               }}
                               style={{width:'220px',height:'60px',fontSize:"26px",borderRadius:"30px",marginLeft:"0px",marginRight:'20px'}}>
                            免费体验
                          </div>
                          <div
                            onClick={() => {
                              sessionStorage.setItem("isActive", 1);
                              sessionStorage.setItem("secondLeveId", 1);
                            }}
                          >
                            <div  onClick={() => {
                              this.props.onVisitClick({
                                type: 3,
                                domain: 'www.qolai.cn',
                                page: 'home',
                                button: 'learnMore'
                              },() => window.location.href = '/errands');
                             }}>
                              <div className="home-tags-button" >
                                <span>了解更多</span>
                              </div>
                            </div>
                          </div>
                        </div> 
                        
                    </div>
                    {/* right */}
                    <div className="home-tags-bottom1-right">
                      <img alt="" src={require('../../asset/homeIcon/img-tag-moduleA1.png')} style={{width:'100%',height:"100%"}}/>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="home-tags-bottom1">

                    {/* left */}
                    <div className="home-tags-bottom1-left">
                        <h1>智能配送调度系统</h1>
                        <p style={{fontSize:'20px',color:'#707070'}}>智能化调度、管理平台订单，统筹管理，全面提升配送效率。</p>
                        <div className="home-tags-bottom1-left-content">
                          <p>
                          利用先进的人工智能技术实现路线与人员的智能规划、调配，后台地图式呈现骑手实时位置，也可人工调度分配实现高效运转；
                          第三方平台，同时开放API接口，技术对接后可实现第三方订单直接发往趣来达配送调度系统；聚合配送，实现一系统多平台配送模式。
                          </p>
                        </div> 
                        <div className="d-flex home-tags-bottom1-left-list">
                          <div className="d-flex flex_column align-cen" style={{marginRight:'55px'}}>
                            <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleB1.png')}/>
                            <span>智能调度</span>
                          </div>
                          <div className="d-flex flex_column align-cen" style={{marginRight:'23px'}}>
                            <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleB2.png')}/>
                            <span>订单指派</span>
                          </div>
                          <div className="d-flex flex_column align-cen" style={{marginRight:'5px'}}>
                            <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleB3.png')}/>
                            <span>对接美团/饿了么</span>
                          </div>
                          <div className="d-flex flex_column align-cen" style={{marginRight:'50px'}}>
                            <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleB4.png')}/>
                            <span>开放API接口</span>
                          </div>
                          <div className="d-flex flex_column align-cen">
                            <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleB5.png')}/>
                            <span>聚合配送</span>
                          </div>
                        </div>
                        <div className="d-flex align-cen" style={{marginTop:"40px"}}>
                          <div className="home-tags-more-button" 
                               onClick={() => {
                                this.props.onVisitClick({
                                  type: 3,
                                  domain: 'www.qolai.cn',
                                  page: 'home',
                                  button: 'experience'
                                },() => window.location.href = 'https://www.qolai.com/admin');
                               }}
                               style={{width:'220px',height:'60px',fontSize:"26px",borderRadius:"30px",marginLeft:"0px",marginRight:'20px'}}>
                            免费体验
                          </div>
                          <div
                            onClick={() => {
                              sessionStorage.setItem("isActive", 1);
                              sessionStorage.setItem("secondLeveId", 1);
                            }}
                          >
                            <div  onClick={() => {
                              this.props.onVisitClick({
                                type: 3,
                                domain: 'www.qolai.cn',
                                page: 'home',
                                button: 'learnMore'
                              },() => window.location.href = '/dispatch_system');
                             }}>
                              <div className="home-tags-button" >
                                <span>了解更多</span>
                              </div>
                            </div>
                          </div>
                        </div> 
                        
                    </div>
                    {/* right */}
                    <div className="home-tags-bottom1-right">
                      <img alt="" src={require('../../asset/homeIcon/img-tag-moduleB1.png')} style={{width:'100%',height:"100%"}}/>
                    </div>

                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <div className="home-tags-bottom1">
                    
                    {/* left */}
                    <div className="home-tags-bottom1-left">
                      <h1>外卖订餐平台系统</h1>
                      <p style={{fontSize:'20px',color:'#707070'}}>面向同城餐饮、生鲜、商超打造的比肩美团的智慧生活平台系统。</p>
                      <div className="home-tags-bottom1-left-content">
                        <p>包含公众号H5网页、小程序(微信、抖音、头条)、APP（Android+IOS）与PC后台，打通线上订餐、外送、结算全流程，契合外卖餐饮、果蔬生鲜、商超连锁等多种零售行业，无缝衔接用户、骑手、商家与平台，提供完善的技术支持。</p>
                      </div> 
                      <div className="d-flex home-tags-bottom1-left-list">
                        <div className="d-flex flex_column align-cen" style={{marginRight:'60px'}}>
                          <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleC1.png')}/>
                          <span>外卖餐饮</span>
                        </div>
                        <div className="d-flex flex_column align-cen" style={{marginRight:'60px'}}>
                          <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleC2.png')}/>
                          <span>果蔬生鲜</span>
                        </div>
                        <div className="d-flex flex_column align-cen" style={{marginRight:'60px'}}>
                          <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleC3.png')}/>
                          <span>商超连锁</span>
                        </div>
                        <div className="d-flex flex_column align-cen">
                          <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleC4.png')}/>
                          <span>零售行业</span>
                        </div>
                      </div>
                      <div className="d-flex align-cen" style={{marginTop:"40px"}}>
                        <div className="home-tags-more-button" 
                             onClick={() => {
                              this.props.onVisitClick({
                                type: 3,
                                domain: 'www.qolai.cn',
                                page: 'home',
                                button: 'experience'
                              },() => window.location.href = 'https://www.qolai.com/admin');
                             }}
                             style={{width:'220px',height:'60px',fontSize:"26px",borderRadius:"30px",marginLeft:"0px",marginRight:'20px'}}>
                          免费体验
                        </div>
                        <div
                            onClick={() => {
                              sessionStorage.setItem("isActive", 1);
                              sessionStorage.setItem("secondLeveId", 1);
                            }}
                          >
                            <div  onClick={() => {
                              this.props.onVisitClick({
                                type: 3,
                                domain: 'www.qolai.cn',
                                page: 'home',
                                button: 'learnMore'
                              },() => window.location.href = '/take_out_food');
                             }}>
                              <div className="home-tags-button" >
                                <span>了解更多</span>
                              </div>
                            </div>
                          </div>
                      </div> 
                        
                    </div>
                    {/* right */}
                    <div className="home-tags-bottom1-right">
                      <img alt="" src={require('../../asset/homeIcon/img-tag-moduleC1.png')} style={{width:'100%',height:"100%"}}/>
                    </div> 
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <div className="home-tags-bottom1">
                      {/* left */}
                    <div className="home-tags-bottom1-left">
                      <h1>校园外卖跑腿生活平台</h1>
                      <p style={{fontSize:'20px',color:'#707070'}}>针对校园餐饮外卖、快递代取、跑腿代办等需求打造的智慧校园系统。</p>
                      <div className="home-tags-bottom1-left-content">
                        <p>面向校园市场特殊性提供贴码、扫码录单、校区选择、转单搜索等一系列针对性的服务，有效提升校园配送效率；拥有站点加盟体系，支持多校区、多站点加盟，分区运营、独立管理。</p>
                      </div> 
                      <div className="d-flex home-tags-bottom1-left-list">
                        <div className="d-flex flex_column align-cen"  style={{marginRight:'60px'}}>
                          <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleD1.png')}/>
                          <span>食堂外送</span>
                        </div>
                        <div className="d-flex flex_column align-cen"  style={{marginRight:'60px'}}>
                          <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleD2.png')}/>
                          <span>快递取送</span>
                        </div>
                        <div className="d-flex flex_column align-cen"  style={{marginRight:'60px'}}>
                          <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleD3.png')}/>
                          <span>分区专送</span>
                        </div>
                        <div className="d-flex flex_column align-cen">
                          <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleD4.png')}/>
                          <span>站点加盟</span>
                        </div>
                      </div>
                      <div className="d-flex align-cen" style={{marginTop:"40px"}}>
                        <div className="home-tags-more-button"
                             onClick={() => {
                              this.props.onVisitClick({
                                type: 3,
                                domain: 'www.qolai.cn',
                                page: 'home',
                                button: 'experience'
                              },() => window.location.href = 'https://www.qolai.com/admin');
                             }}
                             style={{width:'220px',height:'60px',fontSize:"26px",borderRadius:"30px",marginLeft:"0px",marginRight:'20px'}}>
                          免费体验
                        </div>
                        <div
                          onClick={() => {
                            sessionStorage.setItem("isActive", 1);
                            sessionStorage.setItem("secondLeveId", 1);
                          }}
                        >
                          <div onClick={() => {
                              this.props.onVisitClick({
                                type: 3,
                                domain: 'www.qolai.cn',
                                page: 'home',
                                button: 'learnMore'
                              },() => window.location.href = '/campus_platform');
                             }}>
                              <div className="home-tags-button" >
                                <span>了解更多</span>
                              </div>
                            </div>
                        </div>
                      </div> 
                        
                    </div>
                    {/* right */}
                    <div className="home-tags-bottom1-right">
                      <img alt="" src={require('../../asset/homeIcon/img-tag-moduleD1.png')} style={{width:'100%',height:"100%"}}/>
                    </div>     
                  </div>
                </div>
                
                <div className="d-flex justify-content-center">
                  <div className="home-tags-bottom1">
                    {/* left */}
                    <div className="home-tags-bottom1-left">
                      <h1>本地生活O2O系统</h1>
                      <p style={{fontSize:'20px',color:'#707070'}}>搭建集线上点餐、线下消费、购买、接单、打印、调度、配送、物流于一体的平台本地移动生活圈。</p>
                      <div className="home-tags-bottom1-left-content">
                        <p>打造本地万能生活服务平台，整合本地集美食餐饮、酒店民宿、景点门票、丽人美发等多种服务于一体的线上生活平台，成熟的O2O系统，帮助本地生活服务商轻松接轨“互联网+”。</p>
                      </div> 
                      <div className="d-flex home-tags-bottom1-left-list">
                        <div className="d-flex flex_column align-cen"  style={{marginRight:'60px'}}>
                          <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleE1.png')}/>
                          <span>餐饮美食</span>
                        </div>
                        <div className="d-flex flex_column align-cen"  style={{marginRight:'60px'}}>
                          <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleE2.png')}/>
                          <span>酒店民宿</span>
                        </div>
                        <div className="d-flex flex_column align-cen"  style={{marginRight:'60px'}}>
                          <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleE3.png')}/>
                          <span>景点门票</span>
                        </div>
                        <div className="d-flex flex_column align-cen">
                          <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleE4.png')}/>
                          <span>丽人美发</span>
                        </div>
                      </div>
                      <div className="d-flex align-cen" style={{marginTop:"40px"}}>
                        <div className="home-tags-more-button" 
                          onClick={() => {
                            this.props.onVisitClick({
                              type: 3,
                              domain: 'www.qolai.cn',
                              page: 'home',
                              button: 'experience'
                            },() => window.location.href = 'https://www.qolai.com/admin');
                           }}
                          style={{width:'220px',height:'60px',fontSize:"26px",borderRadius:"30px",marginLeft:"0px",marginRight:'20px'}}>
                          免费体验
                        </div>
                        <div
                          onClick={() => {
                            sessionStorage.setItem("isActive", 1);
                            sessionStorage.setItem("secondLeveId", 1);
                          }}
                        >
                          <div onClick={() => {
                              this.props.onVisitClick({
                                type: 3,
                                domain: 'www.qolai.cn',
                                page: 'home',
                                button: 'learnMore'
                              },() => window.location.href = '/system_oto');
                             }}>
                              <div className="home-tags-button" >
                                <span>了解更多</span>
                              </div>
                            </div>
                        </div>
                      </div> 
                        
                    </div>
                    {/* right */}
                    <div className="home-tags-bottom1-right">
                      <img alt="" src={require('../../asset/homeIcon/img-tag-moduleE1.png')} style={{width:'100%',height:"100%"}}/>
                    </div>  

                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <div className="home-tags-bottom1">
                    {/* left */}
                    <div className="home-tags-bottom1-left">
                      <h1>多平台运营系统</h1>
                      <p style={{fontSize:'20px',color:'#707070'}}>在中国，所有的生意都值得再做一次，尤其是在消费升级的时代。</p>
                      <div className="home-tags-bottom1-left-content">
                        <p>趣来达O2O本地平台系统，一次性接入字节旗下各个APP，以小程序为内容载体，利用字节跳动推荐算法与搜索能力把小程序准确推荐给用户，为本地生活业务提供高效的场景搭建和丰富的玩法，借力短视频与直播流量风口，为平台快速营销、宣传、裂变，累积更多用户。</p>
                      </div> 
                      <div className="d-flex jsbet home-tags-bottom1-left-list">
                        <div className="d-flex flex_column align-cen"  style={{marginRight:'50px'}}>
                          <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleF1.png')}/>
                          <span>今日头条</span>
                        </div>
                        <div className="d-flex flex_column align-cen"  style={{marginRight:'50px'}}>
                          <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleF2.png')}/>
                          <span>抖音</span>
                        </div>
                        <div className="d-flex flex_column align-cen"  style={{marginRight:'50px'}}>
                          <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleF3.png')}/>
                          <span>火山小视频</span>
                        </div>
                        <div className="d-flex flex_column align-cen"  style={{marginRight:'50px'}}>
                          <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleF4.png')}/>
                          <span>皮皮虾</span>
                        </div>
                        <div className="d-flex flex_column align-cen"  style={{marginRight:'50px'}}>
                          <img alt="" src={require('../../asset/homeIcon/icon-tag-moduleF5.png')}/>
                          <span>抖音火山版</span>
                        </div>
                      </div>
                      <div className="d-flex align-cen" style={{marginTop:"40px"}}>
                        <div className="home-tags-more-button" 
                           onClick={() => {
                            this.props.onVisitClick({
                              type: 3,
                              domain: 'www.qolai.cn',
                              page: 'home',
                              button: 'experience'
                            },() => window.location.href = 'https://www.qolai.com/admin');
                           }}
                          style={{width:'220px',height:'60px',fontSize:"26px",borderRadius:"30px",marginLeft:"0px",marginRight:'20px'}}>
                          免费体验
                        </div>
                        <div
                          onClick={() => {
                            sessionStorage.setItem("isActive", 1);
                            sessionStorage.setItem("secondLeveId", 1);
                          }}
                        >
                          <div onClick={() => {
                              this.props.onVisitClick({
                                type: 3,
                                domain: 'www.qolai.cn',
                                page: 'home',
                                button: 'learnMore'
                              },() => window.location.href = '/multi_platform');
                             }}>
                              <div className="home-tags-button" >
                                <span>了解更多</span>
                              </div>
                            </div>
                        </div>
                      </div> 
                        
                    </div>
                    {/* right */}
                    <div className="home-tags-bottom1-right">
                      <img alt="" src={require('../../asset/homeIcon/img-tag-moduleF1.png')} style={{width:'100%',height:"100%"}}/>
                    </div>  

                  </div>
                </div>

              </Carousel>
            </Col>
          </Row>
        </div>

        {/* 服务场景 */}
        <div className="main">
          <div className="main" />
          <Title title="服务场景" text="Service Situation" imgUrl={title2} width={638} dec="本地生活服务一网打尽" height={68} />
          <div className="d-flex justify-content-center">
            <div style={{ display: "inline-block" }}>
              <div className="d-flex">
                {deliveryArr.map((item, index) => {
                  return (
                    <div className="delivery-boxa">
                      <div className="d-flex justify-content-center" style={{ marginTop: "50px" }}>
                        <img alt="icon" src={item.imgUrl} style={{ width: "60px", height: "60px" }} />
                      </div>
                      <div className="delivery-title">{item.text}</div>
                      <div className="text-center mx-auto text-describea">
                        {item.describe.map(row => (
                          <span className="fw-600" style={{ display: "block" }}>{row}</span>
                        ))}
                      </div>
                      {/* <div className="text-center mx-auto text-bottom-describea">
                        {item.text}
                      </div> */}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/**缜密逻辑体系，打造领先行业的标杆产品**/}
        <div id="logical" className="main-5" style={{ paddingTop: 80, paddingBottom: 100 }}>
          <Title
            title="趣来达本地O2O平台"
            dec="无缝衔接各端，减少交互流程"
            text="native platform"
            imgUrl={title3}
            width={1170}
            height={66}
          />
          <div>
            {products.map((item, index) => {
              return (
                <div className="d-flex justify-content-center" key={index}>
                  <div
                    className="d-flex"
                    style={{
                      width: "1440px",
                      height: "800px",
                      boxShadow: "0px 8px 30px 0px rgba(162,162,162,0.2)",
                      border: "1px solid #EBEBEB",
                      background: "#ffffff"
                    }}
                  >
                    <div className="logical-system-left">
                      {item.list.map((row, index2) => {
                        return (
                          <div key={index2}>
                            <div
                              className={`logical-system-box ${
                                this.state.plateIndexB === index2 ? "logical-system-box-active" : ""
                              }`}
                              onMouseOver={() => {
                                this.goToIndexb(index2);
                              }}
                            >
                              <div className="logical-system-title">
                                <img alt=""
                                  src={require(`../../asset/icon/${
                                    this.state.plateIndexB === index2 ? `${row.icon}sel` : row.icon
                                  }.png`)}
                                  style={{ marginRight: "13px" }}
                                />
                                <div style={{ marginTop: 26 }}>{row.title}</div>
                              </div>
                              <span />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="logical-system-right">
                      {item.imgArr.map((row1, index3) => {
                        return (
                          <div className="logical-system-img-box" key={index3}>
                            <span
                              className={`logical-system-title1 ${
                                this.state.plateIndexB === row1.number ? "logical-system-title1-active" : ""
                              }`}
                            >
                              {row1.title}
                            </span>
                            <span
                              className={`logical-system-title2 ${
                                this.state.plateIndexB === row1.number ? "logical-system-title1-active" : ""
                              }`}
                            >
                              {row1.dec}
                            </span>
                            <img alt=""
                              className={`logical-system-img ${
                                this.state.plateIndexB === row1.number ? "logical-system-img-active" : ""
                              }`}
                              src={row1.img}
                            />
                            {row1.btn && this.state.plateIndexB === row1.number && (
                              <div style={{ top: row1.code && 285 }} onClick={() => 
                                this.props.onVisitClick({
                                  type: 3,
                                  domain: 'www.qolai.cn',
                                  page: 'home',
                                  button: row1.btn === '点击前往商家后台' ? 'merchantBackground' : 'dispatchingBackground'
                                },() => window.location.href = row1.btn === '点击前往商家后台' ? 'https://www.qolai.com/store' : 'https://www.qolai.com/admin')
                            } className="logical-system-btn">
                                {row1.btn}
                              </div>
                            )}
                            {row1.code && this.state.plateIndexB === row1.number && (
                              <div className="logical-system-code d-flex" style={{top:row1.top ? row1.top : ''}}>
                                {row1.code.map((set, indexs) => (
                                  <div className="system-code-div d-flex" key={indexs}>
                                    <img alt="" style={{ width: 136, height: 136 }} src={set.img} />
                                    <div className="system-code-line" />
                                    <div className="d-flex system-code-right">
                                      {set.codeText.map((k, l) => (
                                        <React.Fragment key={l}>
                                          <span style={{ fontSize: 12, fontWeight: 400, color: "#707070" }}>{k.title}</span>
                                          <img alt="" src={k.icon} style={{ width: 44, height: 44, marginTop: 8 }} />
                                          <h4 style={{ fontSize: 20, fontWeight: 700, color: "#333333", margin: 0 }}>
                                            {k.text}
                                          </h4>
                                          <span style={{ fontSize: 16, fontWeight: 400, color: "#333333", marginTop: 9 }}>
                                            {k.desc}
                                          </span>
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* 合作优势 */}
        <div style={{ position: "relative", height: "940px" }}>
          <div style={{ width: "100%", height: "360px", boxSizing: "border-box" }}>
            <Title
              title="壹立科技"
              dec="以壹为始，立壹之上"
              titleColor="#333333"
              text="YILI technology"
              imgUrl={homeTitle4}
              width={1200}
              height={68}
            />
            <div style={{ position: "absolute", width: "100%" }}>
              <div className="blate4-content">
                <ul>
                  <li>
                    <div className="blate4-content-title">
                      <div
                        style={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          color: "#333333",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 10
                        }}
                      >
                        <img alt=""
                          src="https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/ic_service.png"
                          style={{ width: "30px", height: "30px", marginRight: 9 }}
                        />
                        <span>服务类型</span>
                      </div>
                      <span style={{ fontSize: "16px", color: "#333333", fontWeight: 600 }}>
                        深耕行业数年，壹立科技为国内外2000多位同城生活服务商合作商提供优质的软件系统服务，包括
                        <br />
                        小程序/APP定制，品牌定制，特色功能定制以及产品定制。
                      </span>
                      <div className="more-button" onClick={() => 
                        this.props.onVisitClick({
                          type: 3,
                          domain: 'www.qolai.cn',
                          page: 'home',
                          button: 'learnMore'
                        },() => window.location.href = '/customized?id=1')
                      }>
                        <span>了解更多&nbsp;</span>
                        <span>more+</span>
                      </div>
                    </div>
                    <img alt=""
                      src="https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/juxing1(1).png"
                      style={{ width: "240px", height: "160px" }}
                    />
                  </li>

                  <li style={{ marginLeft: "113px" }}>
                    <div className="blate4-content-title">
                      <div
                        style={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          color: "#333333",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 10
                        }}
                      >
                        <img alt=""
                          src="https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/ic_object.png"
                          style={{ width: "30px", height: "30px", marginRight: 9 }}
                        />
                        <span>服务对象</span>
                      </div>
                      <span style={{ fontSize: "16px", color: "#333333", fontWeight: 600 }}>
                        趣来达同城外卖配送系统，面向本地、区县、社区、校园，为同城/校园创业者提供优质的软件系统服务。
                      </span>
                      <div className="more-button" onClick={() =>
                        this.props.onVisitClick({
                          type: 3,
                          domain: 'www.qolai.cn',
                          page: 'home',
                          button: 'learnMore'
                        },() => window.location.href = '/customized?id=2')}
                        >
                        <span>了解更多&nbsp;</span>
                        <span>more+</span>
                      </div>
                    </div>
                    <img alt=""
                      src="https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/juxing2(2).png"
                      style={{ width: "240px", height: "160px" }}
                    />
                  </li>

                  <li style={{ marginLeft: "226px" }}>
                    <div className="blate4-content-title">
                      <div
                        style={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          color: "#333333",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 10
                        }}
                      >
                        <img alt=""
                          src="https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/ic_advantage.png"
                          style={{ width: "30px", height: "30px", marginRight: 9 }}
                        />
                        <span>服务优势</span>
                      </div>
                      <span style={{ fontSize: "16px", color: "#333333", fontWeight: 600 }}>
                        拥有资深技术研发团队与专业运营团队，为客户提供体系完善的品质服务，连续多年稳健运营，久经市场重重考验，续
                        <br />
                        约率高，深受合作商认同。
                      </span>
                      <div className="more-button" onClick={() => 
                        this.props.onVisitClick({
                          type: 3,
                          domain: 'www.qolai.cn',
                          page: 'home',
                          button: 'learnMore'
                        },() => window.location.href = '/customized?id=3')}>
                        <span>了解更多&nbsp;</span>
                        <span>more+</span>
                      </div>
                    </div>
                    <img alt=""
                      src="https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/juxing1.png"
                      style={{ width: "240px", height: "160px" }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        
        {/* 为什么选择趣来达校园/本地O2O系统？  */}
        <div className="main">
          <div className="main" />
          <div className="d-flex justify-content-center" style={{color:'#121213',fontSize:"36px",fontWeight:'bold',marginBottom:'60px'}}>
            为什么选择趣来达校园/本地O2O系统？       
          </div>
          <div className="d-flex justify-content-center" style={{marginBottom:"70px"}}>
            <div className="wrap" style={{ width: "1440px", display: "flex", justifyContent: "space-between" }}>
              <div className="d-flex O2O-system-list">
                <img alt="" src={require('../../asset/homeIcon/icon-O2O-system1.png')}/>
                <div style={{width:'283px'}}>
                  <p style={{ fontSize:'24px',fontWeight:'bold',color:'#121213', lineHeight: "1" ,marginBottom:'18px'}}>正规专业</p>
                  <p style={{ fontSize:'16px',color:'#707070', lineHeight: "25px" }}>专业互联网软件开发运营商，拥有专业研发团队、自营软件产品、独立知识产权，商标、软著等等，服务国内外4000+企业/团队。</p>
                </div>
              </div>
              <div className="d-flex O2O-system-list">
                <img alt="" src={require('../../asset/homeIcon/icon-O2O-system2.png')}/>
                <div style={{width:'283px'}}>
                  <p style={{ fontSize:'24px',fontWeight:'bold',color:'#121213', lineHeight: "1" ,marginBottom:'18px'}}>安全稳定</p>
                  <p style={{ fontSize:'16px',color:'#707070', lineHeight: "25px" }}>由资深架构师率精英技术团队研发，多年技术积累，服务器自动扩容确保稳定运行，专职运维师全方位监控，为超百万平台用户的业务保驾护航。</p>
                </div>
              </div>
              <div className="d-flex O2O-system-list">
                <img alt="" src={require('../../asset/homeIcon/icon-O2O-system3.png')}/>
                <div style={{width:'283px'}}>
                  <p style={{ fontSize:'24px',fontWeight:'bold',color:'#121213', lineHeight: "1" ,marginBottom:'18px'}}>高频更新</p>
                  <p style={{ fontSize:'16px',color:'#707070', lineHeight: "25px" }}>聆听客户的声音，深入产品运营前线，高效迭代产品，保持月更新频率，不断提升客户体验感，增强市场竞争力。</p>
                </div>
              </div>
              <div className="d-flex O2O-system-list">
                <img alt="" src={require('../../asset/homeIcon/icon-O2O-system4.png')}/>
                <div style={{width:'283px'}}>
                  <p style={{ fontSize:'24px',fontWeight:'bold',color:'#121213', lineHeight: "1" ,marginBottom:'18px'}}>服务优势</p>
                  <p style={{ fontSize:'16px',color:'#707070', lineHeight: "25px" }}>拥有专业的客服和技术团队在线急速响应各种难题，售后一对一专人、专业培训指导，助你轻松创业。</p>
                </div>
              </div>
              <div className="d-flex O2O-system-list">
                <img alt="" src={require('../../asset/homeIcon/icon-O2O-system5.png')}/>
                <div style={{width:'283px'}}>
                  <p style={{ fontSize:'24px',fontWeight:'bold',color:'#121213', lineHeight: "1" ,marginBottom:'18px'}}>运营支持</p>
                  <p style={{ fontSize:'16px',color:'#707070', lineHeight: "25px" }}>总结客户经验，为创业者免费提供运营指导方案，针对区域性或大学生创业者提供各种扶持政策。</p>
                </div>
              </div>
              <div className="d-flex O2O-system-list">
                <img alt="" src={require('../../asset/homeIcon/icon-O2O-system6.png')}/>
                <div style={{width:'283px'}}>
                  <p style={{ fontSize:'24px',fontWeight:'bold',color:'#121213', lineHeight: "1" ,marginBottom:'18px'}}>聆听建议</p>
                  <p style={{ fontSize:'16px',color:'#707070', lineHeight: "25px" }}>定期回访已合作的老客户，收集用户需求与建议，归纳整理统一反馈至产品部，安排后续开发。</p>
                </div>
              </div>
            </div>
          </div>
          
        </div>


        {/**实力互联网技术型企业，全套服务流程**/}
        <div style={{ padding: "80px 0", background: "#F8F8F8",backgroundImage:`url(${require('../../asset/customized/img_bg_case.png')})`,backgroundSize: '100% 100%', backgroundRepeat:' no-repeat' }}>
          <Title
            title="优秀案例"
            dec="技术领跑行业，口碑铸就品牌"
            imgUrl={homeTitle5}
            text="excellent case"
            titleColor='#FFFFFF'
            textColor='rgb(255, 255, 255)'
            decColor='#FFFFFF'
            width={750}
            height={68}
          />

          <div className="d-flex justify-content-center" >
            <div style={{ width: "1440px", display: "flex", justifyContent: "space-between" }}>
              {caseArr.map((item, index) => {
                return (
                  <div className="home-module5-box">
                    <img alt="" src={item.img} style={{ width: "100%", height: "332px" }} />
                    <img alt="" src={item.mask} style={{ width: "100%", height: "332px" }} />
                    <div className="home-module5-box-bottom">
                      <p className="fw-600">{item.text}</p>
                      <div className="home-module5-box-bottom-line" />
                      <div className="home-module5-box-bottom-title">{item.title}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div onClick={()=> {
            this.props.onVisitClick({
              type: 3,
              domain: 'www.qolai.cn',
              page: 'home',
              button: 'industryCaseMore'
            },() => window.location.href = '/case_list')
          }}>
            <div className="industry-Case">
              <span>查看更多行业案例&nbsp;</span>
              <span>more+</span>
            </div>
          </div>
        </div>
        
        
        {/**实力互联网技术型企业，全套服务流程**/}
        <div style={{ padding: "80px 0 0 0", background: "#ffffff"}}>
        <Title title="合作伙伴" text="cooperative partner" width={1009} height={66} />

          <div className="d-flex justify-content-center" >
            <div style={{ width: "1440px", display: "flex", justifyContent: "space-between" }}>
              <div className="module7_columns">
                  <div id="module7_columns_ul">
                      <img alt="" src={require(`../../asset/homeIcon/cooperative-partnerA.png`)} mode="aspectFill" style={{width:" 50%",height:"76px"}}/>
                      <img alt="" src={require(`../../asset/homeIcon/cooperative-partnerA.png`)} mode="aspectFill" style={{width:" 50%",height:"76px"}}/>
                  </div>
                  <div id="module7_columns_ul2">
                      <img alt="" src={require(`../../asset/homeIcon/cooperative-partnerB.png`)} mode="aspectFill" style={{width:" 50%",height:"76px"}}/>
                      <img alt="" src={require(`../../asset/homeIcon/cooperative-partnerB.png`)} mode="aspectFill" style={{width:" 50%",height:"76px"}}/>
                  </div>
                  <div id="module7_columns_ul3">
                      <img alt="" src={require(`../../asset/homeIcon/cooperative-partnerC.png`)} mode="aspectFill" style={{width:" 50%",height:"76px"}}/>
                      <img alt="" src={require(`../../asset/homeIcon/cooperative-partnerC.png`)} mode="aspectFill" style={{width:" 50%",height:"76px"}}/>
                  </div>
                  <div className="maskA"></div>
                  <div className="maskB"></div>
              </div>
            </div>
          </div>
        </div>
        

        {/**实力互联网技术型企业，全套服务流程**/}
        <div style={{ padding: "80px 0", background: " #F7F9FC"}}>
        <Title title="壹立科技，实力企业资质" text="qualification certificate" width={1009} height={66} />

          <div className="d-flex justify-content-center" >
            <div style={{ width: "100%", maxWidth: "1920px", display: "flex", justifyContent: "space-between" }}>
              <div className="module8_columns">
                  <div id="module8_columns_ul">
                      <img alt="" src={require(`../../asset/homeIcon/certifications.png`)} mode="aspectFill" style={{width:" 50%",height:"420px"}}/>
                      <img alt="" src={require(`../../asset/homeIcon/certifications.png`)} mode="aspectFill" style={{width:" 50%",height:"420px"}}/>
                  </div>
                  <div className="maskA"></div>
                  <div className="maskB"></div>
              </div>
            </div>
          </div>
        </div>


        <div id="course" className="main-5" style={{ paddingTop: 80 }}>
          <Title title="新行业资讯" imgUrl={homeTitle6} text="INDUSTRY NEWS" width={1009} height={66} />

          <div className="d-flex justify-content-center">
            <div
              style={{
                width: "1440px",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginBottom: "40px"
              }}
            >
              {this.state.newList.map((item, index) => {
                return (
                  <a key={index} className="information-news-list" href={`/information_detail?id=${item.news_id}`} >
                    <div className="home-module6-box">
                      <img alt=""
                        src={item.news_photos}
                        style={{ width: "189px", height: "126px", borderRadius: "10px", marginRight: "20px" }}
                      />
                      <div className="home-module6-box-right">
                        <div
                          className="home-module6-box-right-title"
                          style={{ fontSize: "24px", color: "#17191A", fontWeight: "bold" }}
                        >
                          {item.news_title}
                        </div>
                        <div className="d-flex justify-content-between home-module6-box-right-content fw-600">{item.remark}</div>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex" style={{ alignItems: "center", fontSize: "14px" }}>
                            <img alt=""
                              src={require(`../../asset/homeIcon/icon_time@2x.png`)}
                              style={{ width: "14px", height: "14px" }}
                            />
                            <span style={{ marginLeft: "5px",color:'rgb(162, 162, 162)' }}>{dayjs(item.create_time).format('YYYY年M月D日')}</span>
                          </div>
                          <div className="d-flex" style={{ alignItems: "center", fontSize: "14px" }}>
                            <img alt=""
                              src={require(`../../asset/homeIcon/icon_browse@2x.png`)}
                              style={{ width: "14px", height: "14px" }}
                            />
                            <span style={{ marginLeft: "5px",color:'rgb(162, 162, 162)' }}>{item.preset_number + item.browse_number}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
          <div onClick={()=>{
             this.props.onVisitClick({
              type: 3,
              domain: 'www.qolai.cn',
              page: 'home',
              button: 'more'
            },() => window.location.href = '/information')
          }}>
            <div className="more-button" style={{ margin: "0 auto 100px auto" }}>
              <span>查看更多&nbsp;</span>
              <span>more+</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
