import React from 'react'
import CarouselIndex from '../../components/Carousel'
import Title from '../../components/Title'
import '../../css/SystemOto.scss'
import { Helmet } from 'react-helmet';


const Carousel_text = [
  {
    carouselImage:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/img_system-oto_banner.png',
    text:[
      {text:'聚焦',colorText:''},
      {text:'，打造本地掌上生活圈',colorText:'同城服务'}
    ],
    desc:'深度挖掘本地生活服务市场客户需求',
    scenes:[
      {text:'聚焦校园外卖、快递代取、跑腿帮办等多元化应用场景'}
    ],
    background: '#324E8F',
    fontWeight:400
  }
]
let pageTimer = '' //停留时间

class DispatchSystem extends React.Component {
  state = {

  };

  componentWillMount() {
    // 获取进入页面的时间
		if (pageTimer === '') {
			pageTimer = parseInt(new Date().getTime() / 1000);
			console.log(pageTimer,'pageTimer')
		}
    this.props.onVisitClick({
      type: 1,
      domain: 'www.qolai.cn',
      page: 'system_oto'
    },0)
  }

  componentWillUnmount() {
    // 离开时间减去进入时间
    const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
    console.log(tempTimer,'tempTimer')
    // 清空时间戳
    pageTimer = '';
    if (tempTimer > 0) {
      this.props.onVisitClick({
        type: 2,
        domain: 'www.qolai.cn',
        page: 'system_oto',
        time: tempTimer
      },0)
    }
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>趣来达同城生活o2o系统-外卖配送软件-同城生活圈</title>
          <meta name="Description" content="趣来达同城生活o2o系统支持跑腿、外卖、快递、代驾、同城信息发布等全方位平台生活服务场景，小程序/H5/App/pc多端口互联实现运营闭环，提供完善的同城服务运营功能，丰富营销、手段人性化的骑手奖惩、等级、管理制度等，趣来达为同城创业者提供全方位同城运营指导方案。" />
          <meta name="Keywords" content="同城生活o2o系统,外卖配送软件,快递代取" />
        </Helmet>
        {/**顶部banner**/}
        <CarouselIndex data={Carousel_text}  getIPs={this.props.getIPs} />

        {/**打造本地多元化生活服务平台**/}
        <div style={{paddingTop:'98px'}}>
          <Title title="打造本地多元化生活服务平台"
            text="service platform"
            width={710}
            height={66} />
          <div className="d-flex justify-content-center flex_column align-cen">
            <div className="d-flex justify-content-center align-cen" style={{marginBottom:'60px'}}>
              <div className="flex-c-c" style={{background:'#FFF1E8',width:'184px',height:'50px',fontSize:'24px',color:'#FF791E',fontWeight:'bold',borderRadius: '6px'}}>
                本地商家入驻
              </div>
              <img alt="" style={{width:'30px',height:'30px',marginLeft:'20px',marginRight:'20px'}} src={require('../../asset/systemoto/icon-plusSign.png')}/>
              <div className="flex-c-c" style={{background:'#E7F3FC',width:'184px',height:'50px',fontSize:'24px',color:'#1887E6',fontWeight:'bold',borderRadius: '6px'}}>
                万能跑腿帮办
              </div>
              <img alt="" style={{width:'30px',height:'30px',marginLeft:'20px',marginRight:'20px'}} src={require('../../asset/systemoto/icon-plusSign.png')}/>
              <div className="flex-c-c" style={{background:'#FEF8E6',width:'184px',height:'50px',fontSize:'24px',color:'#F6BB07',fontWeight:'bold',borderRadius: '6px'}}>
                多种上门服务
              </div>
              <img alt="" style={{width:'30px',height:'30px',marginLeft:'20px',marginRight:'20px'}} src={require('../../asset/systemoto/icon-plusSign.png')}/>
              <div className="flex-c-c" style={{background:'#EDF2FC',width:'184px',height:'50px',fontSize:'24px',color:'#4A7EE6',fontWeight:'bold',borderRadius: '6px'}}>
                到店团购核销
              </div>
            </div>
            <img alt="" src={require(`../../asset/systemoto/system-oto-modulesA-img.png`)} style={{ width: '100%',maxWidth:'1920', height: "670px", marginBottom: '100px' }} />
          </div>
        </div>

        
        {/**配送体系全逻辑流程**/}
        <div style={{ padding: "80px 0 0 0", backgroundImage: `url(${require('../../asset/systemoto/system-oto-modulesB-bg.png')})`, backgroundSize: '100% 100%', backgroundRepeat: ' no-repeat' }}>
          <Title
            title="完善购物体系，便捷本地生活"
            dec="多元化配送方式，确保配送效率高效安全"
            text="convenient life"
            titleColor='#FFFFFF'
            textColor='rgb(255, 255, 255)'
            decColor='#FFFFFF'
            width={750}
            height={68}
          />
          <div className="d-flex justify-content-center">
            <img alt="" src={require(`../../asset/systemoto/system-oto-modulesB-img.png`)} style={{ width: '1440px', height: "670px", marginBottom: '100px' }} />
          </div>
        </div>
        {/**趣来达跑腿配送系统 - 核心亮点**/}
        <div style={{paddingTop:'98px',paddingBottom:"60px"}}>
          <Title title="趣来达同城生活系统 · 核心亮点"
            text="Key highlights"
            width={710}
            height={66}/>
          <div className="d-flex jscen">
            <div className="d-flex align-cen flex_column" style={{ width: "1440px" }}>
              <div className="title-main-box" style={{ marginBottom: "45px" }}>
                <span>页面自定义可视化配置</span>
                <div className="main-bg" style={{zIndex:'1 !important'}}></div>
              </div>
              <div className="d-flex align-cen jsbet" style={{ width: "1440px" }}>
                <div className="d-flex jsbet wrap"style={{width:'630px'}}>
                  <div className="shadowBox" style={{width:'300px',height:'220px',marginRight:"30px",marginBottom:"30px"}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesC-icon1.png`)} style={{ width: '60px', height: "60px", marginBottom: '20px' }} />
                    <p style = {{ fontSize: "20px",fontWeight:'bold',color:'#121213',marginBottom:'5px'}}>多元化页面模板</p>
                    <p style = {{ fontSize: "16px",color:'#707070',marginBottom:'0px',lineHeight:'25.5px'}}>丰富页面可供选择，一键自由切换</p>
                  </div>
                  <div className="shadowBox" style={{width:'300px',height:'220px'}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesC-icon2.png`)} style={{ width: '60px', height: "60px", marginBottom: '20px' }} />
                    <p style = {{ fontSize: "20px",fontWeight:'bold',color:'#121213',marginBottom:'5px'}}>多类模块组件</p>
                    <p style = {{ fontSize: "16px",color:'#707070',marginBottom:'0px',lineHeight:'25.5px'}}>多种功能模块随意选择，自由拖动页面位置</p>
                  </div>
                  <div className="shadowBox" style={{width:'300px',height:'220px',marginRight:"30px",marginBottom:"30px"}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesC-icon3.png`)} style={{ width: '60px', height: "60px", marginBottom: '20px' }} />
                    <p style = {{ fontSize: "20px",fontWeight:'bold',color:'#121213',marginBottom:'5px'}}>自定义样式编辑</p>
                    <p style = {{ fontSize: "16px",color:'#707070',marginBottom:'0px',lineHeight:'25.5px'}}>页面上的图片、文字皆可自定义上传设置</p>
                  </div>
                  <div className="shadowBox" style={{width:'300px',height:'220px'}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesC-icon4.png`)} style={{ width: '60px', height: "60px", marginBottom: '20px' }} />
                    <p style = {{ fontSize: "20px",fontWeight:'bold',color:'#121213',marginBottom:'5px'}}>可视化编辑</p>
                    <p style = {{ fontSize: "16px",color:'#707070',marginBottom:'0px',lineHeight:'25.5px'}}>后台可视化操作，立即预览效果，一键自动保存</p>
                  </div>
                </div>
                <img alt="" src={require(`../../asset/systemoto/system-oto-modulesC-img.png`)} style={{ width: '720px', height: "500px"}} />
              </div>
            </div>
          </div>
        </div>

        

        {/**海量营销功能自由选择设置**/}
        <div style={{ background:'#F7F9FC',paddingTop:'98px',paddingBottom:"60px"}}>
          <div className="d-flex jscen">
            <div className="d-flex align-cen flex_column" style={{ width: "1440px" }}>
              <div className="title-main-box" style={{ marginBottom: "45px" }}>
                <span>海量营销功能自由选择设置</span>
                <div className="main-bg" style={{zIndex:'1 !important'}}></div>
              </div>
              <div className="d-flex align-cen jsbet" style={{ width: "1440px" }}>
              <img alt="" src={require(`../../asset/systemoto/system-oto-modulesD-img.png`)} style={{ width: '720px', height: "500px"}} />
                <div className="d-flex jsbet wrap"style={{width:'630px'}}> 
                  <div className="shadowBox" style={{width:'145px',height:'145px',marginRight:"16px",marginBottom:"16px"}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesD-icon1.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>邀请有礼</p>
                  </div>
                  <div className="shadowBox" style={{width:'145px',height:'145px'}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesD-icon2.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>会员积分</p>
                  </div>
                  <div className="shadowBox" style={{width:'145px',height:'145px'}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesD-icon3.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>组合红包</p>
                  </div>
                  <div className="shadowBox" style={{width:'145px',height:'145px'}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesD-icon4.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>满减活动</p>
                  </div>

                  <div className="shadowBox" style={{width:'145px',height:'145px',marginRight:"16px",marginBottom:"16px"}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesD-icon5.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>减配送费</p>
                  </div>
                  <div className="shadowBox" style={{width:'145px',height:'145px'}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesD-icon6.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>折扣商品</p>
                  </div>
                  <div className="shadowBox" style={{width:'145px',height:'145px'}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesD-icon7.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>优惠团购</p>
                  </div>
                  <div className="shadowBox" style={{width:'145px',height:'145px'}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesD-icon8.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>新客立减</p>
                  </div>
                  
                  <div className="shadowBox" style={{width:'145px',height:'145px',marginRight:"16px",marginBottom:"16px",padding: '30px 0px 0 0'}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesD-icon9.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>充值赠送现金</p>
                  </div>
                  <div className="shadowBox" style={{width:'145px',height:'145px',padding: '30px 0px 0 0' }}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesD-icon10.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px'}} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>充值赠送优惠券</p>
                  </div>
                  <div className="shadowBox" style={{width:'145px',height:'145px',padding: '30px 0px 0 0'  }}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesD-icon11.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px'}} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>新人优惠券</p>
                  </div>
                  <div className="shadowBox" style={{width:'145px',height:'145px'}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesD-icon12.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>折扣优惠券 </p>
                  </div>

                  <div className="shadowBox" style={{width:'145px',height:'145px',marginRight:"16px",marginBottom:"16px"}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesD-icon13.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>定向优惠券</p>
                  </div>
                  <div className="shadowBox" style={{width:'145px',height:'145px'}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesD-icon14.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>弹窗广告</p>
                  </div>
                  <div className="shadowBox" style={{width:'145px',height:'145px'}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesD-icon15.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>组合优惠券</p>
                  </div>
                  <div className="shadowBox" style={{width:'145px',height:'145px'}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesD-icon16.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>更多功能</p>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>


      {/**无限制添加城市分站**/}
      <div style={{ background:'#ffffff',paddingTop:'98px',paddingBottom:"60px"}}>
          <div className="d-flex jscen">
            <div className="d-flex align-cen flex_column" style={{ width: "1440px" }}>
              <div className="title-main-box" style={{ marginBottom: "45px" }}>
                <span>无限制添加城市分站</span>
                <div className="main-bg" style={{zIndex:'1 !important'}}></div>
              </div>
              <div className="d-flex align-cen jsbet" style={{ width: "1440px" }}>
                <div className="d-flex jsbet wrap"style={{width:'630px'}}> 
                  <div className="shadowBox" style={{width:'190px',height:'145px',marginRight:"16px",marginBottom:"16px",padding: '30px 0px 0 0' }}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesE-icon1.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>多个站点共用小程序</p>
                  </div>
                  <div className="shadowBox" style={{width:'190px',height:'145px',padding: '30px 0px 0 0' }}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesE-icon2.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>站点间自由切换</p>
                  </div>
                  <div className="shadowBox" style={{width:'190px',height:'145px'}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesE-icon3.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>分站页面独立设置</p>
                  </div>

                  <div className="shadowBox" style={{width:'190px',height:'145px',marginRight:"16px",marginBottom:"16px"}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesE-icon4.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>分站商家独立结算</p>
                  </div>
                  <div className="shadowBox" style={{width:'190px',height:'145px'}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesE-icon5.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>分站骑手独立管理</p>
                  </div>
                  <div className="shadowBox" style={{width:'190px',height:'145px'}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesE-icon6.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>支持管理本地商家</p>
                  </div>
                  
                  <div className="shadowBox" style={{width:'190px',height:'145px',marginRight:"16px",marginBottom:"16px",padding: '30px 0px 0 0'}}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesC-icon3.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px' }} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>总站权限授予</p>
                  </div>
                  <div className="shadowBox" style={{width:'190px',height:'145px',padding: '30px 0px 0 0' }}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesE-icon8.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px'}} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>分站自由添加</p>
                  </div>
                  <div className="shadowBox" style={{width:'190px',height:'145px',padding: '30px 0px 0 0'  }}> 
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesE-icon9.png`)} style={{ width: '60px', height: "60px", marginBottom: '15px'}} />
                    <p style = {{ fontSize: "16px",fontWeight:'bold',color:'#121213',marginBottom:'15px'}}>站点资金统一管理</p>
                  </div>

                </div>
                <img alt="" src={require(`../../asset/systemoto/system-oto-modulesE-img.png`)} style={{ width: '720px', height: "500px"}} />

              </div>
            </div>
          </div>
        </div>

        {/**海量营销功能自由选择设置**/}
        <div style={{ background:'#F7F9FC',paddingTop:'98px',paddingBottom:"60px"}}>
        <Title title="趣来达同城生活O2O系统优势"
            text="System advantage"
            width={710}
            height={66}/>
          <div className="d-flex jscen">
            <div className="d-flex align-cen jsbet" style={{ width: "1440px" }}>
              <div style={{width:'450px'}}>
                <div className="d-flex flex_column system-oto-modulesF align-end">
                  <div className="flex-c-c system-oto-modulesF-title" style={{background:'#E9EFFB',color:"#4A7EE6"}}>
                    低成本创业
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesF-icon1.png`)} style={{ width: '30px', height: "30px",marginLeft:'7px'}}/>
                  </div>
                  <p style={{marginRight:'20px',marginBottom:'0px',lineHeight:'25.5px'}}>低风险创业项目，投入成本低，购买系统后提供强有力</p>
                  <p style={{marginRight:'20px',marginBottom:'55px',lineHeight:'25.5px'}}>的技术服务支持，后期维护及功能升级全部免费</p>
                </div>
                <div className="d-flex flex_column system-oto-modulesF align-end" style={{width:'405px'}}>
                  <div className="flex-c-c system-oto-modulesF-title"  style={{background:'#FAF5E4',color:"#F6BB07"}}>
                    灵活项目搭配
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesF-icon2.png`)} style={{ width: '30px', height: "30px",marginLeft:'7px'}}/>
                  </div>
                  <p  style={{marginRight:'20px',marginBottom:'0px',lineHeight:'25.5px'}}>支持跑腿、外卖选择性运营，也可以同时运营，灵活项</p>
                  <p  style={{marginRight:'20px',marginBottom:'55px',lineHeight:'25.5px'}}>目处理全方位配合平台发展</p>
                </div>
                <div className="d-flex flex_column system-oto-modulesF align-end">
                  <div className="flex-c-c system-oto-modulesF-title"  style={{background:'#FBEFE6',color:"#FF791E"}}>
                    多端口互联
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesF-icon3.png`)} style={{ width: '30px', height: "30px",marginLeft:'7px'}}/>
                  </div>
                  <p style={{marginRight:'20px',marginBottom:'0px',lineHeight:'25.5px'}}>小程序、H5、APP、PC后台多端互联，无缝衔接用</p>
                  <p style={{marginRight:'20px',marginBottom:'0px',lineHeight:'25.5px'}}>户、商户、骑手和平台运营者，较少交互，多渠道开展</p>
                </div>

              </div>
              <img alt="" src={require(`../../asset/systemoto/system-oto-modulesF-img.png`)} style={{ width: '500px', height: "400px"}} />
              <div style={{width:'450px'}}>
                <div className="d-flex system-oto-modulesF flex_column align-start">
                  <div className="flex-c-c system-oto-modulesF-title" style={{background:'#E6F7F1',color:"#2BCF88"}}>
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesF-icon4.png`)} style={{ width: '30px', height: "30px",marginRight:'7px'}}/>
                    强大功能加持
                  </div>
                  <p style={{marginLeft:'20px',marginBottom:'0px',lineHeight:'25.5px'}}>完善的同城服务运营功能，丰富营销手段、人性化的骑手</p>
                  <p style={{marginLeft:'20px',marginBottom:'55px',lineHeight:'25.5px'}}>奖惩、等级、管理制度与灵活计价处理</p>
                </div>
                <div className="d-flex system-oto-modulesF flex_column align-start" style={{marginLeft:'40px'}}>
                  <div className="flex-c-c system-oto-modulesF-title" style={{background:'#E4F0FB',color:"#1887E6"}}>
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesF-icon5.png`)} style={{ width: '30px', height: "30px",marginRight:'7px'}}/>
                    保姆式扶持
                  </div>
                  <p style={{marginLeft:'20px',marginBottom:'0px',lineHeight:'25.5px'}}>除提供软件技术服务以外，安排专人售后一对一远程服</p>
                  <p style={{marginLeft:'20px',marginBottom:'55px',lineHeight:'25.5px'}}>务，提供运营方案、收益指导、协议模板等文件材料</p>
                </div>
                <div className="d-flex system-oto-modulesF flex_column align-start">
                  <div className="flex-c-c system-oto-modulesF-title" style={{background:'#FAF5E4',color:"#F6BB07"}}>
                    <img alt="" src={require(`../../asset/systemoto/system-oto-modulesF-icon6.png`)} style={{ width: '30px', height: "30px",marginRight:'7px'}}/>
                    数据安全
                  </div>
                  <p style={{marginLeft:'20px',marginBottom:'0px',lineHeight:'25.5px'}}>采用阿里云服务大品牌品质保障，多重防护客户数据安</p>
                  <p style={{marginLeft:'20px',marginBottom:'0px',lineHeight:'25.5px'}}>全，严谨加密处理，为客户做好安全护盾</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default DispatchSystem;