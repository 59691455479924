import axios from 'axios';
import {message} from 'antd';

axios.defaults.baseURL = "https://news.yiliit.com/";

axios.interceptors.request.use((config) => {
    return config
}, error => Promise.reject(error));

if (process.env.NODE_ENV == "production" && navigator.userAgent != "ReactSnap") {
    axios.interceptors.response.use((response) => {
        return response.data
    }, (error) => {
        message.error('数据获取失败，请重试！');
        Promise.reject(error);
        return false;
    })
} else {
    axios.interceptors.response.use((response) => {
        return response.data
    }, (error) => {
        return false;
    })
}
