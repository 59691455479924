import React from "react";
import { Row, Col, Carousel } from "antd";
import "../../App.css";
import "../../css/errands.scss";
import Title from "../../components/Title";
import { Helmet } from 'react-helmet';

import CarouselIndex from '../../components/Carousel';



const style = {
  height: "42px",
  fontSize: "53px",
  fontWeight: 700,
  color: "#121213",
  opacity: 0.08,
  textTransform: "Uppercase",
  display: "block",
  marginTop: "-45px"
};

const Carousel_text = [
  {
    marginTop:"179px",
    carouselImage: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/img_errands_banner.png",
    text: [{ text: "深耕本地跑腿市场，搭建", colorText: "独立品牌 " , opposite:'true'},{ text: "的跑腿平台"}],
    desc:"全场景可视化、DIY配置，搭建特色化平台",
    descTwo:"全面满足需求，为跑腿配送团队提供成熟的解决方案",
    iconscenes:[
      {icon:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/ic_banner6.png',text:'代买代送'},
      {icon:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/ic_banner7.png',text:'万能帮办'},
      {icon:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/ic_banner8.png',text:'上门服务'},
      {icon:'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/ic_banner9.png',text:'自定义场景'}
  ],
    background: '#4B588B',
    
  },
]

let pageTimer = '' //停留时间

class errands extends React.Component {
  state = {
    index: 0,
    codeIndex: 1,
    plateIndex: 0,
    baneerIndex: 1,
    borderIndex: 0,
    timer: "",
    sceneIndex:0,
  };

  componentWillMount() {
    // 获取进入页面的时间
		if (pageTimer === '') {
			pageTimer = parseInt(new Date().getTime() / 1000);
			console.log(pageTimer,'pageTimer')
		}
    this.props.onVisitClick({
      type: 1,
      domain: 'www.qolai.cn',
      page: 'errands'
    },0)
  }

  componentWillUnmount() {
    // 离开时间减去进入时间
    const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
    console.log(tempTimer,'tempTimer')
    // 清空时间戳
    pageTimer = '';
    if (tempTimer > 0) {
      this.props.onVisitClick({
        type: 2,
        domain: 'www.qolai.cn',
        page: 'errands',
        time: tempTimer
      },0)
    }
  }

  slickGoToIndex = index => {
    this.slider.slick.slickGoTo(index);
    this.setState({
      index: index
    });
  };

  goToIndex = index => {
    this.sliders.slick.slickGoTo(index);
    this.setState({
      plateIndex: Number(index)
    });
  };
  perfectSelect = index => {
    this.slidera.slick.slickGoTo(index);
    this.setState({
      borderIndex: Number(index)
    });
  };
  cardChange = (oldIndex, newIndex) => {
    this.setState({
      borderIndex: Number(newIndex)
    });
  };
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
  }
  next() {
    this.slidera.slick.slickNext();
  }
  prev() {
    this.slidera.slick.slickPrev();
  }

  render() {
    const orderIcon = [
      {
        title: "用户端",
        text: ["h5与小程序入口，无需用户下载，多","种生活服务场景，便捷下单。"],
        list: [
          {
            icon: "order_icon1",
            title: "开屏广告"
          },
          {
            icon: "icon_functions2",
            title: "站点/定位选择"
          },
          {
            icon: "icon_functions8",
            title: "邀请有礼"
          },
          {
            icon: "order_icon4",
            title: "优惠券"
          },
          {
            icon: "order_icon5",
            title: "附近骑手"
          },
          {
            icon: "order_icon6",
            title: "位置监控"
          },
          {
            icon: "order_icon7",
            title: "会员积分"
          },
          {
            icon: "order_icon8",
            title: "余额充值赠送"
          }
        ]
      },
      {
        title: "商户端",
        text: ["商家独立APP，发单便捷，响应及","时，所有订单数据皆可追溯。"],
        list: [
          {
            icon: "order_icon9",
            title: "手动录单"
          },
          {
            icon: "order_icon10",
            title: "拍照发单"
          },
          {
            icon: "order_icon11",
            title: "粘贴识别"
          },
          {
            icon: "order_icon12",
            title: "订单打印  "
          },
          {
            icon: "order_icon13",
            title: "常用地址"
          },
          {
            icon: "order_icon14",
            title: "骑手打赏"
          },
          {
            icon: "order_icon15",
            title: "钱包余额"
          },
          {
            icon: "order_icon16",
            title: "在线客服"
          }
        ]
      },
      {
        title: "骑手端",
        text: ["骑手独立APP，响铃及时，抢单、派","单、转单快速响应。"],
        list: [
          {
            icon: "order_icon17",
            title: "实名认证"
          },
          {
            icon: "order_icon18",
            title: "平台保证金"
          },
          {
            icon: "icon_functions8 (1)",
            title: "奖惩制度"
          },
          {
            icon: "icon_functions9",
            title: "等级制度"
          },
          {
            icon: "icon_functions3",
            title: "邀请骑手/商家"
          },
          {
            icon: "order_icon22",
            title: "余额提现"
          },
          {
            icon: "icon_functions10",
            title: "扫码录单"
          },
          {
            icon: "order_icon24",
            title: "骑手保险"
          }
        ]
      },
      {
        title: "调度后台",
        text: ["运营者管理调度中心，包含用户、商","户、骑手管理与所有平台数据管理。"],
        list: [
          {
            icon: "order_icon25",
            title: "场景配置"
          },
          {
            icon: "order_icon26",
            title: "可视化操作 "
          },
          {
            icon: "icon_functions(4)8",
            title: "跑腿模板"
          },
          {
            icon: "icon_functions(4)9",
            title: "站点加盟"
          },
          {
            icon: "order_icon29",
            title: "智能调度 "
          },
          {
            icon: "icon_functions(4)4",
            title: "计价方案设置 "
          },
          {
            icon: "order_icon31",
            title: "营销活动"
          },
          {
            icon: "order_icon32",
            title: "数据统计"
          }
        ]
      }
    ];

    const perfectSystem = [
      {
        list: [
          {
            title: "01 用户/商户下单",
            number: 0,
            title2: "用户通过小程序/H5快捷下单，商户有独立APP便捷下单",
            icon: "icon_perfect1"
          },
          {
            title: "02 支付订单",
            number: 1,
            title2: "可通过微信支付/储备金支付订单，也可以选择货到付款",
            icon: "icon_perfect2"
          },
          {
            title: "03 骑手接单",
            number: 2,
            title2: "骑手通过抢单池抢单,或接收指派订单，也可以转单或拒单",
            icon: "icon_perfect3"
          },
          {
            title: "04 跑腿代办",
            number: 3,
            title2: "订单进行过程中，骑手可以地图导航，位置实时定位实现订单跟踪",
            icon: "icon_perfect4"
          },
          {
            title: "05 订单完成",
            number: 4,
            title2: "完成订单后骑手所得佣金和赏金直接到达账号，随时申请提现",
            icon: "icon_perfect5"
          },
          {
            title: "06 订单评价",
            number: 5,
            title2: "订单完成后,用户可对骑手进行星级打分,评价配送服务纳入等级考核",
            icon: "icon_perfect6"
          }
        ]
      }
    ];

    const templateIcon = [
      {
        icon:"icon_template1",
        title:"多种模板选择"
      },{
        icon:"icon_template2",
        title:"一键风格切换"
      },{
        icon:"icon_template3",
        title:"首页颜色DIY"
      },{
        icon:"icon_template4",
        title:"底部Table自定义"
      },{
        icon:"icon_template5",
        title:"场景DIY"
      },{
        icon:"icon_template6",
        title:"广告图自定义"
      }
    ]

    const imgarr = [
      {
        img: "img_system1"
      },
      {
        img: "img_system2"
      },
      {
        img: "img_system3"
      },
      {
        img: "img_system4"
      },
      {
        img: "img_system5"
      },
      {
        img: "img_system6"
      }
    ];

    const scopeArr = [
      {
        img:'img-scope1',
        title:'同城代买'
      },
      {
        img:'img-scope2',
        title:'快递代取'
      },
      {
        img:'img-scope3',
        title:'万能帮办'
      },
      {
        img:'img-scope4',
        title:'餐饮配送'
      },
      {
        img:'img-scope5',
        title:'上门服务'
      },
      {
        img:'img-scope6',
        title:'代驾打车'
      },
      {
        img:'img-scope7',
        title:'异城货运'
      }
    ]

    return (
      <div>
        <Helmet>
          <title>趣来达-跑腿配送系统-跑腿平台-跑腿小程序</title>
          <meta name="Description" content="趣来达-趣来达跑腿配送系统深耕本地跑腿市场，搭建独立品牌多站点运营，全场景可视化、DIY配置，搭建特色化平台，全面满足跑腿的代买代送、万能帮办、上门服务等创业需求，为跑腿配送团队提供成熟的解决方案。" />
          <meta name="Keywords" content="跑腿配送系统,跑腿平台,跑腿小程序" />
        </Helmet>
        {/**顶部banner**/}
        <CarouselIndex data={Carousel_text}  getIPs={this.props.getIPs}/>
        {/**多元化跑腿模板，打破传统运营格局**/}
        <div style={{ paddingTop: "80px", paddingBottom: "80px" }}>
          <Title title="多元化跑腿模板，打破传统运营格局" text="template" width={710} height={66} />
          <div className="d-flex justify-content-center">
            <div className="d-flex flex_column" style={{ width: "1440px" }}>
            <div className="detaile-template">
                {templateIcon.map((item, index) => {
                  return (
                    <div className="box">
                        <img alt="" src={require(`../../asset/detaile/${item.icon}.png`)} style={{width:'100px',height:'100px'}}/>
                        <label>{item.title}</label>
                    </div>  
                  );
                })}
              </div>
              <img alt="" src={require(`../../asset/detaile/img_template.png`)} style={{width:'100%',height:'560px'}}/>
            </div>
          </div>
        </div>
        {/**自定义跑腿服务场景，加速市场拓展**/}
        <div className="service-scenario" style={{ paddingTop: "60px", paddingBottom: "80px",height:'1000px',background:'#27272e',borderRadius:'160px 160px 0 0'}}>
          <Title title="自定义跑腿服务场景，加速市场拓展" 
                 text="SERVICE SCENARIO"
                 titleColor='#FFFFFF'
                 textColor='rgb(255, 255, 255)'
                 decColor='#FFFFFF'
                 width={710} 
                 height={66} />
          <div className="d-flex justify-content-center" style={{ width: "100%" }}>
            <div className="d-flex flex_column" style={{ width: "100%" }}>
              <div className="d-flex flex-start" style={{ width: "100%",height:'120px' }}>
                <div className={this.state.sceneIndex === 0 ?'d-flex posre active' :"d-flex posre"}  
                     onMouseOver={() => {
                      this.setState({
                          sceneIndex:0
                        })
                      }}
                     style={{backgroundImage:`url(${require('../../asset/detaile/service-scenario-bg1.png')})`,backgroundSize: '100% 100%', backgroundRepeat:' no-repeat',width: "75%",height:'120px',zIndex:1}}>
                  <div className="posab d-flex flex-end scenario-left"> 
                    <p>场景名称自定义设置，场景图标自定义上传，个性化配置</p>
                    <img alt="" src={require(`../../asset/detaile/service-scenario-icon1.png`)}/>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-end"  style={{ width: "100%",height:'120px' }}>
                <div className={this.state.sceneIndex === 1 ?'d-flex posre active' :"d-flex posre"} 
                     onMouseOver={() => {
                      this.setState({
                          sceneIndex:1
                        })
                     }}
                     style={{backgroundImage:`url(${require('../../asset/detaile/service-scenario-bg2.png')})`,backgroundSize: '100% 100%', backgroundRepeat:' no-repeat',width: "75%",height:'120px',zIndex:1}}>
                  <div className="posab d-flex flex-start scenario-right"> 
                    <img alt="" src={require(`../../asset/detaile/service-scenario-icon2.png`)}/>
                    <p>后台提供丰富发单字段模块可供选择，位置自由拖动</p>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-start"  style={{ width: "100%",height:'120px' }}>
                <div className={this.state.sceneIndex === 2 ?'d-flex posre active' :"d-flex posre"} 
                     onMouseOver={() => {
                      this.setState({
                          sceneIndex:2
                        })
                     }}
                     style={{backgroundImage:`url(${require('../../asset/detaile/service-scenario-bg3.png')})`,backgroundSize: '100% 100%', backgroundRepeat:' no-repeat',width: "83%",height:'120px',zIndex:1}}>
                  <div className="posab d-flex flex-end scenario-left"> 
                    <p>不同场景可设置独立计价方式，多种计价模式自由搭配</p>
                    <img alt="" src={require(`../../asset/detaile/service-scenario-icon3.png`)}/>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-end"  style={{ width: "100%",height:'120px' }}>
                <div className={this.state.sceneIndex === 3 ?'d-flex posre active' :"d-flex posre"} 
                     onMouseOver={() => {
                      this.setState({
                          sceneIndex:3
                        })
                     }}
                     style={{backgroundImage:`url(${require('../../asset/detaile/service-scenario-bg4.png')})`,backgroundSize: '100% 100%', backgroundRepeat:' no-repeat',width: "83%",height:'120px',zIndex:1}}>
                  <div className="posab d-flex flex-start scenario-right"> 
                    <img alt="" src={require(`../../asset/detaile/service-scenario-icon4.png`)}/>
                    <p>支持时段附加、天气附加、服务选择附加等多种附加计价</p>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-start" style={{ width: "100%",height:'120px' }}>
                <div className={this.state.sceneIndex === 4 ?'d-flex posre active' :"d-flex posre"} 
                     onMouseOver={() => {
                      this.setState({
                          sceneIndex:4
                        })
                     }}
                     style={{backgroundImage:`url(${require('../../asset/detaile/service-scenario-bg5.png')})`,backgroundSize: '100% 100%', backgroundRepeat:' no-repeat',width: "75%",height:'120px',zIndex:1}}>
                  <div className="posab d-flex flex-end scenario-left"> 
                    <p>系统支持在线支付、余额支付和货到付款三种付费方式</p>
                    <img alt="" src={require(`../../asset/detaile/service-scenario-icon5.png`)}/>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-end" style={{ width: "100%",height:'120px' }}>
                <div className={this.state.sceneIndex === 5 ?'d-flex posre active' :"d-flex posre"} 
                     onMouseOver={() => {
                      this.setState({
                          sceneIndex:5
                        })
                     }}
                     style={{backgroundImage:`url(${require('../../asset/detaile/service-scenario-bg6.png')})`,backgroundSize: '100% 100%', backgroundRepeat:' no-repeat',width: "75%",height:'120px',zIndex:1}}>
                  <div className="posab d-flex flex-start scenario-right"> 
                    <img alt="" src={require(`../../asset/detaile/service-scenario-icon6.png`)}/>
                    <p>支持赏金追加、消费打赏等增值内容配置</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/**趣来达跑腿配送系统 - 价值亮点**/}
        <div style={{ paddingTop: "80px", paddingBottom: "80px" }}>
          <div className="d-flex justify-content-center">
            <div className="d-flex flex_column" style={{ width: "1440px" }}>
            <div className="service-scope-top">
                {scopeArr.map((item, index) => {
                  return (
                    <div className={index > 3 ? 'box width460' : 'box mb30'}>
                      <div className="box-warp" style={{width:'100%',height:'100%',backgroundImage:`url(${require(`../../asset/detaile/${item.img}.png`)})`,backgroundSize: '100% 100%', backgroundRepeat:' no-repeat'}}>
                        
                      </div>
                      <div className="scope-content">
                          <div className="scope-content-title">
                            {item.title}
                          </div> 
                        </div> 
                    </div>  
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/**趣来达提供全面的订单调度管理**/}
        <div style={{ paddingTop: "80px",}}>
          <Title title="趣来达跑腿配送系统 - 价值亮点" text="bright spot" width={710} height={66} />
          <div className="d-flex justify-content-center">
            <div className="posre" style={{ width: "1440px" }}>
              {/* top */}
              <div className="valueWindow-top">
                <div className="valueWindow-list" style={{ marginTop: "210px" }}>
                  <img alt="" src={require("../../asset/detaile/icon-value-window1.png")}/>
                  <p style={{fontSize:'24px',fontWeight:'bold',marginBottom:'19px',color:"#121213",lineHeight:'1'}}>跳转外部小程序</p>
                  <p style={{fontSize:'16px',color:"#707070",lineHeight:'26px',marginBottom:'0px'}}>
                    趣来达跑腿小程序支持跳转其他外部小程序，实现不同程序间的流量互换，也可作为广告盈利。
                  </p>
                </div>
                <div className="valueWindow-list">
                  <img alt="" src={require("../../asset/detaile/icon-value-window2.png")}/>
                  <p style={{fontSize:'24px',fontWeight:'bold',marginBottom:'19px',color:"#121213",lineHeight:'1'}}>多站点区域化运营</p>
                  <p style={{fontSize:'16px',color:"#707070",lineHeight:'26px',marginBottom:'0px'}}>
                    支持多城市、多站点区域化平台运营，不同站点独立设置，站点选择自由切换。
                  </p>
                </div> 
                <div className="valueWindow-list" style={{ marginTop: "210px" }}>
                  <img alt="" src={require("../../asset/detaile/icon-value-window3.png")}/>
                  <p style={{fontSize:'24px',fontWeight:'bold',marginBottom:'19px',color:"#121213",lineHeight:'1'}}>收取件码</p>
                  <p style={{fontSize:'16px',color:"#707070",lineHeight:'26px',marginBottom:'0px'}}>
                    在跑腿取送过程中可设置收取件码，保障成功寄件、取货，更安全、合理化运营平台。
                  </p>
                </div>
              </div>
                {/* bottom */}
              <div className="valueWindow-top">
                <div className="valueWindow-list">
                  <img alt="" src={require("../../asset/detaile/icon-value-window4.png")}/>
                  <p style={{fontSize:'24px',fontWeight:'bold',marginBottom:'19px',color:"#121213",lineHeight:'1'}}>服务时间预约</p>
                  <p style={{fontSize:'16px',color:"#707070",lineHeight:'26px',marginBottom:'0px'}}>
                  系统支持服务时间预约制，可自定义时间预约差，客户下单自由选择，提前下单。
                  </p>
                </div>
                <div className="valueWindow-list" style={{ marginTop: "210px" }}>
                  <img alt="" src={require("../../asset/detaile/icon-value-window5.png")}/>
                  <p style={{fontSize:'24px',fontWeight:'bold',marginBottom:'19px',color:"#121213",lineHeight:'1'}}>会员积分</p>
                  <p style={{fontSize:'16px',color:"#707070",lineHeight:'26px',marginBottom:'0px'}}>
                  会员积分自定义，增加用户粘性；积分商城自配置，提升用户兴趣。
                  </p>
                </div>
                <div className="valueWindow-list">
                  <img alt="" src={require("../../asset/detaile/icon-value-window6.png")}/>
                  <p style={{fontSize:'24px',fontWeight:'bold',marginBottom:'19px',color:"#121213",lineHeight:'1'}}>邀请有礼</p>
                  <p style={{fontSize:'16px',color:"#707070",lineHeight:'26px',marginBottom:'0px'}}>
                  系统内设有邀请有礼功能，邀请新用户、新骑手、新商家成功可获取佣金奖励。
                  </p>
                </div>
              </div>
              <img alt="" style={{position:'absolute',width:'500px',height:'400px',top:'350px',right:'470px'}} src={require("../../asset/detaile/img-value-window.png")}/>
            </div>
          </div>
        </div>
        {/* 代买 - 代送 - 代办 - 自定义服务场景 */}
        {/* <div>
          <Title title="代买 - 代送 - 代办 - 自定义服务场景" text="INDUSTRY NEWS" width={710} height={66} />
          <Customize arr={customizeArr} />
        </div> */}
        {/* 完善配送体系，为同城即时配送加速 */}
        <div id="distribution" style={{ position: "relative", }}>
          <div className="main-2-backgroud" />
          <Row>
            <Col span={8}>
              <div className="main-2-left">
                {/* <div type="right-circle" onClick={this.next}/> */}
                <Carousel
                  type="card"
                  autoplay={true}
                  beforeChange={(oldIndex, newIndex) => {
                    this.cardChange(oldIndex, newIndex);
                  }}
                  effect="fade"
                  ref={slider => (this.slidera = slider)}
                >
                  {imgarr.map((item, index) => {
                    return (
                      <div key={index}>
                        <img alt="" src={require(`../../asset/detaile/${item.img}.png`)} style={{ width: "413px", height: "773px" }} />
                      </div>
                    );
                  })}
                </Carousel>
                <div className="main-2-switch">
                  <img alt="" src={require("../../asset/detaile/icon_left.png")} onClick={this.prev} />
                  <img alt="" src={require("../../asset/detaile/icon_right.png")} onClick={this.next} />
                </div>
              </div>
            </Col>
            <Col span={16} style={{ paddingTop: "51px" }}>
              <div className="main-2-title" style={{ marginLeft: "45px", marginBottom: "81px", position: "relative" }}>
                <span style={{ color: "rgb(51, 51, 51)", fontSize: "36px" }}> 完善配送体系，为同城即时配送加速</span>
                {/* <img alt="" alt="icon" src={require('../../asset/perfectSystem.png')}  style={{ marginTop:'-25px'}}/> */}
                <span style={{ ...style }}>perfect system</span>
                <span className="d-block title-line-between" style={{ position: "absolute", bottom: "-20px", left: "3px" }} />
              </div>
              {perfectSystem.map((item, index) => {
                return (
                  <div className="perfect-system-box" key={index}>
                    {item.list.map((row, index1) => {
                      return (
                        <div
                          onMouseOver={() => {
                            this.perfectSelect(index1);
                          }}
                          className={`perfect-system-list ${
                            this.state.borderIndex === row.number ? "perfect-system-list-active" : ""
                          }`}
                          key={index1}
                        >
                          <span style={{ fontSize: "20px", color: "#333333", fontWeight: "bold", marginLeft: "24px" }}>
                            {row.title}
                          </span>
                          <img alt="" className="perfect-system-border-img" src={require("../../asset/detaile/img_border.png")} />
                          <div className="perfect-system-bottom">
                            <div className="perfect-system-bottom-left">
                              <span
                                style={{
                                  width: "40px",
                                  height: "2px",
                                  display: "block",
                                  background: "#333333",
                                  marginBottom: "12px"
                                }}
                              />
                              <img alt="" src={require(`../../asset/detaile/${row.icon}.png`)} style={{ marginLeft: "5px" }} />
                            </div>
                            <div
                              className="perfect-system-bottom-right"
                              style={{ color: "#333333", fontSize: "16px", lineHeight: "26px" }}
                            >
                              {row.title2}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </Col>
          </Row>
        </div>
        {/**趣来达提供全面的订单调度管理**/}
        <div style={{ paddingTop: "80px", paddingBottom: "80px" }}>
          <Title title="特色功能，满足跑腿平台多样需求" text="YILI technology" width={710} height={66} />
          <div className="d-flex justify-content-center">
            <div className="d-flex" style={{ width: "1440px" }}>
              {orderIcon.map((item, index) => {
                return (
                  <div className="detaile-module3-box">
                    <div className={`detaile-module3-box-content ${index === 3 ? "" : "border-right"}`}>
                      <div className="detaile-module3-box-content-top">
                        <div className="detaile-module3-box-content-top-title">{item.title}</div>
                        <div style={{ fontSize: "16px", textAlign: "center", color: "#707070" ,fontWeight:'600'}}>
                          {
                            item.text.map((row,col) => <span style={{display:'block'}} key={col}>{row}</span>)
                          }
                        </div>
                      </div>
                      <div className="detaile-module3-box-content-bottom">
                        {item.list.map((v, k) => {
                          return (
                            <div className="detaile-module3-bottom-item">
                              <img alt="" src={require(`../../asset/detaile/${v.icon}.png`)} />
                              <div>{v.title}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/**提供全面的订单调度管理**/}
        {/* <div className="main-3">
          <Title
            title="提供全面的订单调度管理"
            dec="实时了解订单配送进程，在线监控骑手位置"
            text="excellent case"
            width={710}
            height={66}
          />
          <Row>
            <Col sm={24} xl={24}>
              <div className="tag-main d-flex justify-content-center">
                <div
                  className={this.state.index === 0 ? "tag-active tag" : "tag"}
                  id="tag1"
                  onMouseEnter={() => {
                    this.slickGoToIndex(0);
                  }}
                >
                  订单调度管理
                </div>
                <span />
                <div
                  className={this.state.index === 1 ? "tag-active tag" : "tag"}
                  id="tag2"
                  onMouseEnter={() => {
                    this.slickGoToIndex(1);
                  }}
                >
                  查看配送进程
                </div>
                <span />
                <div
                  className={this.state.index === 2 ? "tag-active tag" : "tag"}
                  id="tag3"
                  onMouseEnter={() => {
                    this.slickGoToIndex(2);
                  }}
                >
                  监控配送员位置
                </div>
              </div>
            </Col>
            <Col sm={24} xl={24} style={{ marginTop: "80px" }}>
              <Carousel dec={false} ref={slider => (this.slider = slider)}>
                <div className="d-flex justify-content-center">
                  <div className="detaile-banner1">
                    
                    <div>
                      <div className="d-inline-block detaile-main-left-1">
                        <h5 className="detaile-plate3-title">侧边导航栏</h5>
                        <p className="detaile-plate3-dec">导航栏可点击收缩、展开</p>
                      </div>
                      <div className="d-inline-block detaile-main-left-2">
                        <h5 className="detaile-plate3-title">订单状态分类</h5>
                        <p className="detaile-plate3-dec">默认显示新订单,可根据不同状态订单查看订单信息</p>
                      </div>
                      <div className="d-inline-block detaile-main-left-3">
                        <h5 className="detaile-plate3-title">订单信息列表</h5>
                        <p className="detaile-plate3-dec">
                          订单列表按照发入时间越早排在越前面的顺序排列,便于调度员及时发现,进行处理
                        </p>
                      </div>
                    </div>
                    
                    <div>
                      <div className="d-inline-block detaile-main-right-1 text-left">
                        <h5 className="detaile-plate3-title">操作栏</h5>
                        <p className="detaile-plate3-dec">
                          可根据客户信息、商户信息、配送员信息、支付方式、下单时间、项目名称、订单号等查询订单
                        </p>
                      </div>
                      <div className="d-inline-block detaile-main-right-2 text-left">
                        <h5 className="detaile-plate3-title">订单操作</h5>
                        <p className="detaile-plate3-dec">管理员可以查看订单详情,操作指派、改派、撤销订单</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="detaile-banner2">
                    
                    <div>
                      <div className="d-inline-block detaile-main2-left-1 text-right">
                        <h5 className="detaile-plate3-title">查看订单状态</h5>
                        <p className="detaile-plate3-dec" />
                      </div>
                      <div className="d-inline-block detaile-main2-left-2 text-right">
                        <h5 className="detaile-plate3-title">查看订单进程状态</h5>
                        <p className="detaile-plate3-dec" />
                      </div>
                    </div>
                    
                    <div>
                      <div className="d-inline-block detaile-main2-right-1 text-left">
                        <h5 className="detaile-plate3-title">查看订单操作人</h5>
                        <p className="detaile-plate3-dec" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center" style={{ height: 506 }}>
                  <img alt=""
                    src={require("../../asset/detaile/banner3.png")}
                    className="banner3"
                    alt="banner"
                    style={{ visibility: "hidden" }}
                  />
                  <div className="detaile-banner3">
                    
                    <div>
                      <div className="d-inline-block detaile-main3-left-1 text-right">
                        <h5 className="detaile-plate3-title">搜索配送员</h5>
                        <p className="detaile-plate3-dec">搜索配送员姓名,查找某个配送员</p>
                      </div>
                      <div className="d-inline-block detaile-main3-left-2 text-right">
                        <h5 className="detaile-plate3-title">查看配送员</h5>
                        <p className="detaile-plate3-dec">点击查看可以看到配送员所在位置</p>
                      </div>
                    </div>

                    <div>
                      <div className="d-inline-block detaile-main3-right-1 text-left">
                        <h5 className="detaile-plate3-title">点击配送员</h5>
                        <p className="detaile-plate3-dec">点击配送员,可实时更新坐标和持单信息</p>
                      </div>
                      <div className="d-inline-block detaile-main3-right-2 text-left">
                        <h5 className="detaile-plate3-title">配送员状态</h5>
                        <p className="detaile-plate3-dec">管理员可以查看配送员当前状态</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel>
            </Col>
            <Col sm={24} xl={24} className="text-center">
              <Button
                ghost
                onClick={() => {
                  this.props.showDialog();
                }}
                className="order-manage-btn"
              >
                立即试用<span style={{fontWeight:700,marginLeft:5}}> more+</span>
              </Button>
            </Col>
          </Row>
        </div> */}

        {/**为您量身打造的全平台服务体系只为满足您的个性化使用习惯**/}
        {/* <div className="main-5" style={{ paddingTop: 80 }}>
          <Title title="合作优势" text="excellent case" imgUrl={title3} width={1170} height={66} />
          <div className="d-flex justify-content-center">
            <div className="detaile-module5-box">
              {module5Arr.map(item => {
                return (
                  <div className="detaile-module5-box-item">
                    <div className="detaile-module5-box-item-top">
                      <div className="detaile-module5-box-item-top-radius">
                        <img alt="" src={require(`../../asset/detaile/${item.icon}.png`)} style={{ width: "50px", height: "50px" }} />
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "33px",
                        fontSize: "20px",
                        color: "#333333",
                        marginBottom: "23px",
                        fontWeight: "bold"
                      }}
                    >
                      {item.title}
                    </div>
                    <div style={{ fontSize: "14px", color: "#707070", fontWeight:'600' }}>{item.text}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div> */}
      
      </div>
    );
  }
}

export default errands;
