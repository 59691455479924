import React from 'react';
import { message } from "antd";
import { Helmet } from 'react-helmet';
// components
import Banner from '../../components/Banner';
// apis
import { newsDetail, getOtherNews } from '../../apis/InformationDetail';
// utils
import { getURLParameters } from '../../utils/index';

class InformationDetail extends React.Component {
  state = {
    newsContent: null,
    newsTitle: '',
    createTime: '',
    newsId: null,

    startModel: {},
    endModel: {}
  }

  async componentDidMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    let data = getURLParameters(window.location.href)
    this.getNewsDetail(data.id);
  }
  // 获取新闻详情
  getNewsDetail = async newsId => {
    const result = await newsDetail(5, newsId);
    if (result.code !== 200) {
      message.error(result.message || '获取失败');
      return
    }
    console.log(result)
    this.setState({
      newsContent: result.data.news_content,
      newsTitle: result.data.news_title,
      createTime: result.data.create_time,
      newsId: result.data.news_id
    });
    // 获取上一片和下一片
    const OtherNewsResult = await getOtherNews(5, newsId);
    if (OtherNewsResult.code !== 200) {
      message.error(result.message || '获取失败');
      return
    }
    this.setState({
      startModel: OtherNewsResult.data.startModel || null,
      endModel: OtherNewsResult.data.endModel || null
    })
  };

  render() {
    return (
      <div >
        <Helmet>
          <title>趣来达-{this.state.newsTitle}-趣来达</title>
        </Helmet>
        <Banner
          showDialog={() => {this.props.showDialog()}}
          bannerClass="information-banner"
          title="追踪全行业新动态"
          titles="了解科技发展新风向 "
        />
        <div className="containers" style={{marginTop: '80px',marginBottom: '80px'}}>
          <div className="information-page-header d-flex align-items-center">
            <img alt="img" src={require('../../asset/information/icon1.png')} style={{width: '20px', height: '20px'}}/>
            <span>当前位置：趣来达 > <span onClick={() => {this.props.history.goBack()}}>行业资讯</span> > 资讯详情</span>
          </div>
          <h4 className="newsDetail-title">{this.state.newsTitle}</h4>
          <div className="newsDetail-createTime">
            <span>发布于{this.state.createTime}</span>
          </div>
          <div style={{padding: '20px 0'}} className='html-box' dangerouslySetInnerHTML={{ __html: this.state.newsContent}}>
          </div>
          <div className="d-flex justify-content-center border-top">
            {
              this.state.startModel === null ? <span style={{width: '50%'}}></span> : <a
                href={`/information_detail?id=${this.state.startModel.news_id}`}
                className="newsDetail-other-news"
              >
                上一篇{this.state.startModel.news_title}
              </a>
            }
            {
              this.state.endModel === null ? <span style={{width: '50%'}}></span> : <a
                href={`/information_detail?id=${this.state.endModel.news_id}`}
                className="newsDetail-other-news"
              >
                下一篇{this.state.endModel.news_title}
              </a>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default InformationDetail;
