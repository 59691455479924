import React from 'react';
import { Carousel, message } from 'antd';
import axios from 'axios';
import '../css/Carousel.scss'
import {
  visitClick
} from '../apis/App';

const key = 'updatable';

export default React.memo((props) => {
  const { data, getIPs } = props
  const [phone,setPhone] = React.useState('');
  const [de,setDe] = React.useState(false);
  const submit = async() => {
    let data = {
      phone: phone,
      type: 4
    }
    if(phone.length < 11 || phone == ''|| !(/^1[3456789]\d{9}$/.test(phone))){
      message.warning('请输入正确的手机号码')
      return
    }
    if(de) return
    setDe(true);
    message.loading({ content: '提交中...', key });
    const Result = await axios.post('/api/Official/saveOfficialFrom',data);
    if(Result.code !== 200){
      message.error( Result.message || '提交失败');
      return
    }
    message.success({ content: '提交成功!', key, duration: 2 });
    setDe(false);
  }

  // 官网访问点击
  const onVisitClick = async (data, onSuccess) => {
    let hide = null
    if (onSuccess !== 0) {
      hide = message.loading('', 0)
    }
    const ip = await getIPs();
    const parameter = {
      ip,
      ...data
    }
    const res = await visitClick(parameter)
    hide && hide();
    if (res.code !== 200) {
      // message.error('请重试！')
      return;
    }
    onSuccess && onSuccess();
  }

  return (
    <div className='carousel-view' style={{height:'680px',overflow:'hidden'}}>
      <Carousel autoplay={true}
                autoplaySpeed={3000} className="carousel-view-active">
        {
          data.map((item,index) => (
            item.type == 'douyin' ?
            // 抖音轮播
            <div className='Carousel-index' key={index}>
              <img src={item.carouselImage} style={{width: '100%', height:' 100%'}} alt=""/>
              <div className='Carousel-content'>
                <div className='Carousel-douyin'>
                  <div className='animated fadeInUp'>
                    <h3 style={{marginBottom: item.marginBottom? item.marginBottom : ''}}>
                      {
                        item.text.map((row,col) => (
                          <React.Fragment key={col}>
                            <span className='colorText' style={{color:row.opposite ? '#FFFFFF': '#F6BB07'}}>{row.opposite ? row.text : row.colorText}</span>
                            <span className='Text' style={{color:row.opposite ? '#F6BB07': '#FFFFFF'}}>{row.opposite ? row.colorText: row.text}</span>
                            {row.br && <br/>}
                          </React.Fragment>
                        ))
                      }
                    </h3>
                    {
                      item.icons && <img src={item.icons} style={{width: '1440px', height:'180px'}}/>
                    }
                    {
                      item.textTwo &&
                      <div className="textTwo">
                        {
                          item.textTwo.map((row,col)=>{
                            return (
                              <div>
                                <span style={{color:'#FFFFFF'}}>{row.text}</span>
                                <span style={{color:'#F6BB07'}}>{row.colorText}</span>
                              </div>
                            )
                          })
                        }
                      </div>
                    }
                  </div>
                  <div className='Carousel-button d-flex animated fadeInRight'>
                      <input type="number" onChange={(e) => {if(e.target.value.length > 11) e.target.value = e.target.value.slice(0,11);setPhone(e.target.value) }} placeholder='请输入您的手机号' />
                      <div onClick={()=> {
                        onVisitClick({
                          type: 3,
                          domain: 'www.qolai.cn',
                          page: window.location.pathname.split('/')[1] ? window.location.pathname.split('/')[1]: 'home',
                          button: 'registerUse'
                        },0);
                        submit()}} className='button'>免费注册使用</div>
                  </div>
                </div>
              </div>
            </div> :
            <div className='Carousel-index' key={index}>
              <img src={item.carouselImage} style={{width: '100%', height:' 100%'}} alt=""/>
              <div className='Carousel-content' style={{maxWidth:item.maxWidth ? item.maxWidth : '1440px'}}>
                <div className='Carousel-item' style={{marginTop:item.marginTop ? item.marginTop : ''}}>
                  <div className='animated fadeInRight'>
                    <h3>
                      {
                        item.text.map((row,col) => (
                            <React.Fragment key={col}>
                              <span className='colorText' style={{color:row.opposite ? '#FFFFFF': '#F6BB07'}}>{row.opposite ? row.text : row.colorText}</span>
                              <span className='Text' style={{color:row.opposite ? '#F6BB07': '#FFFFFF'}}>{row.opposite ? row.colorText: row.text}</span>
                              {row.br && <br/>}
                            </React.Fragment>
                        ))
                      }
                    </h3>
                    {
                        item.desc && <span className='desc'>{item.desc}</span>
                    }

                    <br/>
                    {
                        item.descTwo && <span className='desc'>{item.descTwo}</span>
                    }

                    {item.scenes && item.scenes.length !== 0 &&
                        <div>
                          <div className='scenes' style={{backgroundColor:item.background && item.background}}>
                            {
                              item.scenes.map((row,col) => (
                                  <React.Fragment key={col}>
                                    {row.icon && <img src={row.icon} />}
                                    <span style={{fontWeight:item.fontWeight && item.fontWeight}}>{row.text}</span>
                                  </React.Fragment>
                              ))
                            }
                          </div>
                        </div>
                    }
                    {
                        item.characteristic &&  item.characteristic.length !== 0 &&
                        <div className='characteristic-div'>
                          {
                            item.characteristic.map((row,col) => (
                                <div className='characteristic' key={col}>
                                  <span>{row.text}</span>
                                </div>
                            ))
                          }
                        </div>
                    }
                    {
                        item.iconscenes && item.iconscenes.length !== 0 &&
                        <div className='iconscenes-div'>
                          {
                            item.iconscenes.map((row,col) => (
                                <div className={row.gray ? 'iconscenes-gray' : 'iconscenes'} style={{width:row.width ? row.width : ''}} key={col} >
                                  <img src={row.icon} />
                                  <span>{row.text}</span>
                                </div>
                            ))
                          }
                        </div>
                    }
                  </div>
                  <div className='Carousel-button d-flex animated fadeInRight'>
                    <input type="number" onChange={(e) => {if(e.target.value.length > 11) e.target.value = e.target.value.slice(0,11);setPhone(e.target.value) }} placeholder='请输入您的手机号' />
                    <div onClick={()=> {
                      submit();
                      console.log(window.location.pathname.split('/'));
                      onVisitClick({
                        type: 3,
                        domain: 'www.qolai.cn',
                        page: window.location.pathname.split('/')[1] ? window.location.pathname.split('/')[1]: 'home',
                        button: window.location.pathname.split('/')[1] == 'seft' ? 'free_use' : ''
                      },0);
                      submit()}} className='button'>{window.location.pathname.split('/')[1] == 'self' ? '申请免费体验': '免费注册使用'}</div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
        
      </Carousel>
    </div>
  );
})
