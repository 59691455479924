import React from 'react';
import { Button, Row, Col } from 'antd';

export default function Banner(props) {
  return (
    <div className={`${props.bannerClass} d-flex align-items-center`} >
      <Row className="w-100">
        <Col span={12} />
        <Col span={12}>
        <div className="banner-title" style={{marginLeft:props.marginLeft + 'px'}}>
        <div className="animated fadeInRight">
          <h1 className="global-banner-text mb-0" style={{fontSize:props.fontSize + 'px',letterSpacing:props.letterSpacing + 'px',fontWeight:props.fontWeight}} >{props.title}</h1>
          {props.titles && <h1 className="global-banner-text mb-0">{props.titles}</h1>}
          {props.dec && <div className="global-banner-span" style={{marginTop:props.top + 'px'}}>{props.dec}</div>}
          {props.dec && <div className="global-banner-span">{props.decs}</div>}
        </div>
        {props.isRegister && <Button ghost onClick={() => window.location.href = 'https://www.qolai.com/admin#/user/register'} className="global-banner-btn animated fadeInRight">立即注册</Button>}
        {!props.isRegister && <Button ghost onClick={() => {props.showDialog()}} className="global-banner-btn animated fadeInRight">{props.btnTitle}</Button>}
      </div>
      </Col>
      </Row>
    </div>
  )
}

Banner.defaultProps = {
  bannerClass: 'global-banner',
  decs: '',
  marginLeft:'',
  btnTitle:'立即试用',
  titles: '',
  fontWeight:'',
  fontSize:'',
  letterSpacing:'',
  dec: '',
  isRegister: false,
  top:''
}
